import { useQuery } from "@tanstack/react-query";
import { RolePermissionsAPI } from "api";

const fetchRolePermissions = (roleId) => {
  return RolePermissionsAPI.get({ params: { roleId } });
};
export const useFetchRolesPermissions = (roleId) => {
  return useQuery({
    queryKey: ["permissions", roleId],
    queryFn: () => fetchRolePermissions(roleId),
  });
};
