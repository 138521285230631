import {
  closestCorners,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { LibraryAPI } from "api";
import ActionButton from "components/common/ActionButton";
import { useFetchProducts } from "hooks/useFetchProducts";

import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  FloatingLabel,
  Form,
  FormControl,
  InputGroup,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SortableItem = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <td
        {...listeners}
        {...attributes}
        style={{
          cursor: "grab",
          textAlign: "center",
          width: "10px",
          // paddingTop: "15px",
        }}
      >
        ☰
      </td>
      {children}
    </tr>
  );
};

const RelatedProducts = () => {
  const {
    register,
    getValues,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    fields: relatedProducts,
    append: appendRelatedProducts,
    remove: removeRelatedProducts,
    move,
  } = useFieldArray({
    control,
    name: "relatedProducts",
  });

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = relatedProducts.findIndex(
        (item) => item.id === active.id
      );
      const newIndex = relatedProducts.findIndex((item) => item.id === over.id);
      move(oldIndex, newIndex);
    }
  };

  const { data, isLoading } = useFetchProducts();

  // if (isLoading) {
  //   return <Spinner variant="primary" animation="border" />;
  // }

  return (
    <>
      {/* <Card.Header as="h6" className="bg-body-tertiary mb-2">
        Shipping And Warranty
      </Card.Header> */}

      {isLoading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <Row className="gx-2 ">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={relatedProducts.map((item) => item.id)}
              strategy={verticalListSortingStrategy}
            >
              <Table responsive>
                <thead className="bg-200">
                  <tr>
                    <th scope="col" className="w-0 fs-10 text-center">
                      Order
                    </th>

                    <th scope="col" className="fs-10">
                      Product Name
                    </th>

                    <th
                      style={{ width: "10px" }}
                      scope="col"
                      className="fs-10 text-center"
                    >
                      Status
                    </th>

                    <th
                      scope="col"
                      style={{ padding: "9px", width: "10px" }}
                      className=" text-end"
                    >
                      <ActionButton
                        icon="plus"
                        variant="success"
                        title="Add"
                        className="px-1 py-0"
                        onClick={() => appendRelatedProducts({})}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {relatedProducts.map((item, index) => (
                    <SortableItem key={item.id} id={item.id}>
                      <td style={{ padding: "5px" }}>
                        <Form.Group controlId="formGroupName">
                          <Form.Select
                            aria-label="fieldSets"
                            isInvalid={
                              !!errors.relatedProducts?.[index]
                                ?.relatedProductId
                            }
                            {...register(
                              `relatedProducts[${index}].relatedProductId`
                            )}
                          >
                            <option value="">Select</option>
                            {data?.data?.data.map((d) => (
                              <option value={d.id}>{d.name}</option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {
                              errors.relatedProducts?.[index]?.relatedProductId
                                ?.message
                            }
                          </Form.Control.Feedback>
                        </Form.Group>
                      </td>
                      <td style={{ padding: "5px" }} className="text-center">
                        <Form.Check
                          type="checkbox"
                          id="defaultSwitch"
                          className="mt-1"
                          defaultChecked={!state}
                          isInvalid={!!errors.relatedProducts?.[index]?.status}
                          {...register(`relatedProducts[${index}].status`)}
                        />
                      </td>
                      <td
                        style={{ padding: "5px", maxWidth: "10px" }}
                        className="text-center"
                      >
                        <ActionButton
                          icon="trash-alt"
                          title="Delete"
                          variant="danger"
                          className="px-1 py-0"
                          onClick={() => removeRelatedProducts(index)}
                        />
                      </td>
                    </SortableItem>
                  ))}
                </tbody>
              </Table>
            </SortableContext>
          </DndContext>
        </Row>
      )}
    </>
  );
};

export default RelatedProducts;
