import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button, Col, Form, Row } from "react-bootstrap";
import classNames from "classnames";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup
  .object({
    password: yup.string().required("This field is required."),
    confirmPassword: yup
      .string()
      .required("This field is required.")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

const PasswordResetForm = ({ hasLabel }) => {
  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    console.log(data);
    // ------- Get all object keys form data and set empty values to reset ------------
    const keys = Object.keys(data);
    for (const key of keys) {
      submittedValues[key] = "";
    }
    reset({ ...submittedValues });
  };

  return (
    <Form
      className={classNames("mt-3", { "text-left": hasLabel })}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "New Password" : ""}
          type="password"
          isInvalid={!!errors.password}
          {...register("password")}
        />
        <Form.Control.Feedback type="invalid" className="d-flex">
          {errors.password?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Confirm Password" : ""}
          type="password"
          isInvalid={!!errors.confirmPassword}
          {...register("confirmPassword")}
        />
        <Form.Control.Feedback type="invalid" className="d-flex">
          {errors.confirmPassword?.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        // disabled={!formData.password || !formData.confirmPassword}
      >
        Set password
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool,
};

export default PasswordResetForm;
