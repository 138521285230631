import { useQuery } from "@tanstack/react-query";
import { ProductsAPI } from "api";

const fetchData = () => {
  return ProductsAPI.get();
};
export const useFetchProducts = () => {
  return useQuery({
    queryKey: ["products"],
    queryFn: fetchData,
  });
};
