import i18n from "i18n";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { MdOutlineLanguage } from "react-icons/md";
import en from "assets/img/lng/en.png";
import fr from "assets/img/lng/fr.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LanguageControlDropdown = ({ dropdownClassName }) => {
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("language") || "en" // Default to English if no language is set
  );

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  useEffect(() => {
    const savedLang = localStorage.getItem("language") || "en";
    setCurrentLanguage(savedLang); // Ensure the component re-renders if the language is changed outside this component
  }, []);

  return (
    <Dropdown
      navbar={true}
      as="div"
      className={`theme-control-dropdown ${
        dropdownClassName ? dropdownClassName : ""
      }`}
    >
      <Dropdown.Toggle
        bsPrefix="toggle"
        variant="link"
        className="nav-link dropdown-toggle d-flex align-items-center pe-1"
      >
        <MdOutlineLanguage size={20} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end mt-2">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item
            active={currentLanguage === "en"}
            onClick={() => handleLanguageChange("en")}
          >
            <img src={en} width={25} height={18} className="mx-1" />
            English
            {/* {currentLanguage === "en" && (
              <FontAwesomeIcon icon="check" className="ms-auto text-600 mx-1" />
            )} */}
          </Dropdown.Item>
          <Dropdown.Item
            active={currentLanguage === "fr"}
            onClick={() => handleLanguageChange("fr")}
          >
            <img src={fr} width={25} height="auto" className="mx-1" />
            French
            {/* {currentLanguage === "fr" && (
              <FontAwesomeIcon icon="check" className="ms-auto text-600 mx-1" />
            )} */}
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageControlDropdown;
