import { useQuery } from "@tanstack/react-query";
import { CategoriesGroupAPI } from "api";

const fetchData = () => {
  return CategoriesGroupAPI.get();
};
export const useFetchCategoriesGroup = () => {
  return useQuery({
    queryKey: ["categories-group"],
    queryFn: fetchData,
  });
};
