import { useQuery } from "@tanstack/react-query";
import { CategoryVariantsAPI } from "api";

const fetchCategoryVariants = () => {
  return CategoryVariantsAPI.get();
};
export const useFetchCategoryVariants = () => {
  return useQuery({
    queryKey: ["category-variants"],
    queryFn: fetchCategoryVariants,
  });
};
