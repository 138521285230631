import { Checkbox } from "@mui/material";
import ActionButton from "components/common/ActionButton";
import { useAppContext } from "Main";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { FaPlusSquare } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";

const FieldPropertiesSubSubModal = ({
  show,
  setShow,
  applySubSubProperties,
  subSubFieldKey,
}) => {
  const {
    config: { isDark },
  } = useAppContext();

  const [formData, setFormData] = useState({
    name: "",
    label: "",
    placeholder: "",
    readOnly: false,
    required: false,
    regex: "",
    defaultValue: "",
    sortOrder: "",
    groupForRadioOrCheckBox: "",
    listValues: "",
    subSubOptions: [{ name: "", children: [] }],
    styles: {},
  });

  const [subSubHierarchicalOptions, setSubSubHierarchicalOptions] = useState([
    { name: "", children: [] },
  ]);

  const handleClose = () => {
    setShow(!show);
  };

  const handleChange = ({ target }) => {
    const { name, type, checked, value } = target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const handleHierarchicalChange = (index, event) => {
    const { name, value } = event.target;
    const updatedHierarchicalOptions = [...subSubHierarchicalOptions];
    updatedHierarchicalOptions[index] = {
      ...updatedHierarchicalOptions[index],
      [name]: value,
    };
    setSubSubHierarchicalOptions(updatedHierarchicalOptions);
    setFormData((prevFormData) => ({
      ...prevFormData,
      subSubOptions: updatedHierarchicalOptions,
    }));
  };

  const addHierarchicalOption = () => {
    setSubSubHierarchicalOptions([
      ...subSubHierarchicalOptions,
      { name: "", children: [] },
    ]);
  };

  const removeHierarchicalOption = (index) => {
    setSubSubHierarchicalOptions(
      subSubHierarchicalOptions.filter((_, i) => i !== index)
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    applySubSubProperties(subSubFieldKey, formData);
    handleClose();
  };

  useEffect(() => {
    setFormData({
      name: "",
      label: "",
      placeholder: "",
      readOnly: false,
      required: false,
      regex: "",
      defaultValue: "",
      sortOrder: "",
      groupForRadioOrCheckBox: "",
      listValues: "",
      subSubOptions: subSubHierarchicalOptions,
      styles: {},
    });
    setSubSubHierarchicalOptions([{ name: "", children: [] }]);
  }, [show]);

  return (
    <>
      <Modal
        // size="lg"
        show={show}
        onHide={handleClose}
        contentClassName="border"
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header
            closeButton
            closeVariant={isDark ? "white" : undefined}
            className="bg-body-tertiary px-x1 border-bottom-0"
          >
            <Modal.Title as="h5">Sub Sub Field Properties</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-x1">
            <div className="mt-2 mb-4 grid grid-cols-2 gap-4">
              <div className="d-flex justify-content-between mb-1">
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>Label:</label>
                <input
                  type="text"
                  name="label"
                  value={formData.label}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>Placeholder:</label>
                <input
                  type="text"
                  name="placeholder"
                  value={formData.placeholder}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>Background Color:</label>
                <input
                  type="text"
                  name="backgroundColor"
                  value={formData.styles.backgroundColor || ""}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      styles: {
                        ...prevFormData.styles,
                        backgroundColor: e.target.value,
                      },
                    }))
                  }
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>Color:</label>
                <input
                  type="text"
                  name="color"
                  value={formData.styles.color || ""}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      styles: {
                        ...prevFormData.styles,
                        color: e.target.value,
                      },
                    }))
                  }
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>Width:</label>
                <input
                  type="text"
                  name="width"
                  value={formData.styles.width || ""}
                  onChange={(e) =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      styles: {
                        ...prevFormData.styles,
                        width: e.target.value,
                      },
                    }))
                  }
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>RegEx:</label>
                <input
                  type="text"
                  name="regex"
                  value={formData.regex}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>SortOrder:</label>
                <input
                  type="text"
                  name="sortOrder"
                  value={formData.sortOrder}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>DefaultValue:</label>
                <input
                  type="text"
                  name="defaultValue"
                  value={formData.defaultValue}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                />
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>Is Read Only:</label>
                <Checkbox
                  name="readOnly"
                  onChange={handleChange}
                  checked={formData?.readOnly}
                  className="p-0"
                />
                {/* <input
                  type="checkbox"
                  name="readOnly"
                  checked={formData.readOnly}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                /> */}
              </div>
              <div className="d-flex justify-content-between mb-1">
                <label>Is Required:</label>
                {/* <input
                  type="checkbox"
                  name="required"
                  checked={formData.required}
                  onChange={handleChange}
                  className="border border-gray-300 rounded px-1"
                /> */}
                <Checkbox
                  name="required"
                  onChange={handleChange}
                  checked={formData?.required}
                  className="p-0"
                />
              </div>
            </div>
            {/* ================== Hierarchical Select ===================== */}

            <div className="mt-4">
              <Table responsive>
                <thead className="bg-200">
                  <tr>
                    <th scope="col" className="fs-10">
                      Hierarchical Select
                    </th>
                    <th className="text-end" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {subSubHierarchicalOptions.map((option, index) => (
                    <tr key={option.id}>
                      <td className="px-4 py-2">
                        <input
                          type="text"
                          name="name"
                          value={option.name}
                          onChange={(e) => handleHierarchicalChange(index, e)}
                          style={{ width: "100%" }}
                          className="border border-gray-300 rounded px-1 "
                        />
                      </td>

                      <td className="px-4 py-2 text-center">
                        {index === subSubHierarchicalOptions.length - 1 ? (
                          <div className="flex items-center gap-2 justify-center">
                            <ActionButton
                              icon="plus"
                              variant="success"
                              title="Add"
                              className="px-1 py-0"
                              onClick={addHierarchicalOption}
                            />
                          </div>
                        ) : (
                          <div className="flex items-center gap-2 justify-center">
                            <ActionButton
                              icon="trash-alt"
                              title="Delete"
                              variant="danger"
                              className="px-1 py-0"
                              onClick={() => removeHierarchicalOption(index)}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-body-tertiary px-x1 border-top-0">
            <div onClick={handleClose} className="px-4 mx-0 cursor-pointer">
              Cancel
            </div>
            <Button
              onClick={handleSubmit}
              variant="primary"
              type="button"
              className="px-4 mx-0"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default FieldPropertiesSubSubModal;
