import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import BreadCrumb from "components/breadcrumb/BreadCrumb";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { RolesAPI, UpdateProfileAPI } from "api";
import { toast } from "react-toastify";
import { useAppContext } from "Main";
import { useFetchRoles } from "hooks/useFetchRoles";

const schema = yup
  .object({
    name: yup.string().required("This field is required."),
    loginId: yup.string().required("This field is required."),
    // roleName: yup.string(),
    role: yup.string().required("This field is required."),
    type: yup.string().required("This field is required."),
    designation: yup.string().required("This field is required."),
  })
  .required();

const ProfileSetting = () => {
  const [user, setUser] = useState({});
  // const [userRoleName, setUserRoleName] = useState("");
  const userFound = JSON.parse(localStorage.getItem("user"));

  const { data: roles, isFetching, isLoading } = useFetchRoles();

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: userFound
      ? {
          name: userFound.name || "",
          loginId: userFound.loginId || "",
          role: userFound.role || "",
          type: userFound.type || "",
          designation: userFound.designation || "",
        }
      : {},
  });
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    console.log(data);
    let myData = { ...data };
    const roleName = roles?.data.data.find((d) => d.id == data.role);
    {
      myData.password = "";
      myData.roleName = roleName ? roleName?.name : "";
      myData.email = "";
      myData.avatar = "";
      //   "avatar": "string"

      // {
      //   "loginId": "string",
      //   "password": "string",
      //   "type": "string",
      //   "role": 0,
      //   "roleName": "string",
      //   "name": "string",
      //   "designation": "string",
      //   "email": "string",
      //   "avatar": "string"
      // }
    }

    console.log(myData);
    try {
      await UpdateProfileAPI.post(myData);
      toast.success("Posted", { position: "top-right", autoClose: 2000 });
    } catch (error) {
      toast.error("Something Wrong", {
        position: "top-right",
        autoClose: 2000,
      });
      console.log(error);
    }
  };

  if (isFetching || isLoading) {
    return <Spinner animation="border" variant="primary" />;
  }

  // useEffect(() => {
  //   if (location.state) {
  //     reset(location.state);
  //   }
  // }, []);

  // useEffect(() => {
  //   // const userFound = localStorage.getItem("user");
  //   if (!!userFound) {
  //     setLoading(true);
  //     setTimeout(() => reset(userFound), [2000]);
  //     setLoading(false);
  //   }
  // }, []);

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="gx-2 gy-3">
            <Col md="12">
              <Form.Group>
                {/* <Form.Label>First name:</Form.Label> */}
                <FloatingLabel
                  controlId="floatingInput"
                  label="Name"
                  className=""
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    isInvalid={!!errors.name}
                    {...register("name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>

            <Col md="12">
              <Form.Group>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Login Id"
                  className=""
                >
                  <Form.Control
                    type="text"
                    placeholder="name@example.com"
                    isInvalid={!!errors.loginId}
                    {...register("loginId")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.loginId?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Designation"
                  className=""
                >
                  <Form.Control
                    type="text"
                    placeholder="designation"
                    isInvalid={!!errors.designation}
                    {...register("designation")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.designation?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Type"
                  className=""
                >
                  <Form.Control
                    type="text"
                    placeholder="type"
                    isInvalid={!!errors.type}
                    {...register("type")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.type?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>

            <Col md="12">
              <Form.Group>
                <FloatingLabel controlId="floatingSelectGrid" label="Role">
                  <Form.Select
                    aria-label="role"
                    isInvalid={!!errors.role}
                    {...register("role")}
                  >
                    <option value="">Select</option>
                    {roles?.data.data.map((d, index) => (
                      <option key={d.id} value={d?.id}>
                        {d.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.role?.message}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </Col>

            <Col md="12">
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Avatar</Form.Label>
                <Form.Control
                  type="file"
                  size="lg"
                  isInvalid={!!errors.logo}
                  {...register("avatar")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.avatar?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="gx-2 gy-3 my-1">
            <Col className="d-flex justify-content-end">
              <Button
                variant="link"
                className="text-secondary fw-medium p-0 me-3"
                type="button"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Ok
                {/* Save */}
              </Button>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default ProfileSetting;
