import ActionButton from "components/common/ActionButton";
import LightBoxLatestExample from "components/common/LightBoxLatestExample";
import SubtleBadge from "components/common/SubtleBadge";
import { useFetchCategoryVariants } from "hooks/useFetchCategoryVariants";
import { useFetchDetailsFieldSets } from "hooks/useFetchDetailFieldSets";
import { useFetchVariants } from "hooks/useFetchDetailVariants";
import useQuery from "hooks/useQuery";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Image,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Variants = () => {
  const {
    register,
    getValues,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const categoryId = watch("categoryId");

  const { data: variantsData, isLoading: variantsLoading } = useFetchVariants();

  const { data: categoryVariantsData, isLoading: categoryVariantsLoading } =
    useFetchCategoryVariants();

  const filteredCategoryVariants = useMemo(() => {
    if (!categoryVariantsData || !categoryId) return [];
    return categoryVariantsData?.data?.data?.filter(
      (set) => set.categoryId === categoryId
    );
  }, [categoryVariantsData, categoryId]);

  const variantsIds = useMemo(() => {
    return filteredCategoryVariants?.flatMap((set) =>
      set.options.map((fs) => fs.variantId)
    );
  }, [filteredCategoryVariants]);

  const filteredVariantsOption = useMemo(() => {
    if (!variantsData || variantsIds.length === 0) return [];
    return variantsData?.data?.data?.filter((variant) =>
      variantsIds.includes(variant.id)
    );
  }, [variantsData, variantsIds]);

  // console.log(filteredVariantsOption);

  // if (variantsLoading || categoryVariantsLoading) {
  //   return <Spinner variant="primary" animation="border" />;
  // }

  return (
    <>
      {variantsLoading || categoryVariantsLoading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <Row className="gx-2">
          {filteredVariantsOption.map((data, index) => (
            <Row key={index}>
              <Card.Header as="h6" className="bg-body-tertiary text-800 ">
                {data.name}
              </Card.Header>
              <div className="mb-3">
                <Card.Body className="" style={{ padding: "0px" }}>
                  <Row className=" my-1">
                    <Table responsive>
                      <thead className="bg-200">
                        <tr>
                          <th scope="col" className="fs-10">
                            Name
                          </th>
                          <th scope="col" className="fs-10">
                            Options
                          </th>
                          <th scope="col" className="fs-10">
                            Images
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.options?.map((item, index) => (
                          <tr key={index}>
                            <td style={{ padding: "5px", width: "30%" }}>
                              <Form.Control
                                type="text"
                                placeholder="Name"
                                value={item.name}
                                disabled
                              />
                            </td>
                            <td style={{ width: "30%", padding: "5px" }}>
                              <div className="form-control d-flex flex-wrap align-items-center">
                                {item?.options?.map((tag, tagIndex) => (
                                  <SubtleBadge
                                    key={tagIndex}
                                    className="mr-2 mb-1 mx-1"
                                  >
                                    {tag}
                                    <span
                                      className="ml-2"
                                      style={{ cursor: "pointer" }}
                                    >
                                      &times;
                                    </span>
                                  </SubtleBadge>
                                ))}
                              </div>
                            </td>
                            <td style={{ width: "30%", padding: "5px" }}>
                              {/* {console.log(item.images)} */}
                              {/* <div className="form-control d-flex flex-wrap align-items-center"> */}
                              {/* <Row className="g-2">
                            {item?.images?.map((image, index) => (
                              <Col
                                xs={6}
                                md={4}
                                lg={3}
                                key={index}
                                className="position-relative"
                              >
                                <div className="d-flex gap-2 align-items-center">
                                  <img
                                    src={`data:image/${image?.fileName
                                      ?.split(".")
                                      .pop()};base64,${image?.fileBase64}`}
                                    width="100%"
                                    style={{
                                      display: "block",
                                      borderRadius: "8px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </Col>
                            ))}
                          </Row> */}

                              <LightBoxLatestExample
                                images={
                                  item?.images?.map(
                                    (image) =>
                                      `data:image/${image?.fileName
                                        ?.split(".")
                                        .pop()};base64,${image?.fileBase64}`
                                  ) || []
                                }
                              >
                                {(setImgIndex) => (
                                  <Row className="g-2">
                                    {item?.images?.map((image, imgIndex) => (
                                      <Col
                                        xs={6}
                                        md={4}
                                        lg={3}
                                        key={imgIndex}
                                        className="position-relative"
                                      >
                                        <div className="d-flex gap-2 align-items-center">
                                          <img
                                            src={`data:image/${image?.fileName
                                              ?.split(".")
                                              .pop()};base64,${
                                              image?.fileBase64
                                            }`}
                                            width="100%"
                                            style={{
                                              display: "block",
                                              borderRadius: "8px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              setImgIndex(imgIndex)
                                            } // Open lightbox with selected image
                                          />
                                        </div>
                                      </Col>
                                    ))}
                                  </Row>
                                )}
                              </LightBoxLatestExample>

                              {/* </div> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Row>
                </Card.Body>
              </div>
            </Row>
          ))}
        </Row>
      )}
    </>
  );
};

export default Variants;
