import { useQuery } from "@tanstack/react-query";
import { LibraryAPI, RolesAPI } from "api";

const fetchRoles = () => {
  return RolesAPI.get();
};
export const useFetchRoles = () => {
  return useQuery({
    queryKey: ["roles"],
    queryFn: fetchRoles,
  });
};
