import { useQuery } from "@tanstack/react-query";
import { VariantsAPI } from "api";

const fetchVariants = () => {
  return VariantsAPI.get();
};
export const useFetchVariants = () => {
  return useQuery({
    queryKey: ["variants"],
    queryFn: fetchVariants,
  });
};
