import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import WizardInput from "./WizardInput";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFetchCategoriesGroup } from "hooks/useFetchCategoriesGroup";
import { useFetchCategoriesById } from "hooks/useFetchCategoriesById";

const StepTwoForm = ({
  register,
  errors,
  setValue,
  clearErrors,
  selectedItem,
  setSelectedItem,
  data,
}) => {
  // console.log(selectedItem);

  const handleItemClick = (d) => {
    // Select the item
    setSelectedItem(d);
    setValue("category", d); // Update the form value
    clearErrors("category"); // Clear any errors for category
  };

  return (
    <>
      {data && (
        <div className="container">
          <div className="row g-3">
            {" "}
            {/* Apply gap utilities here */}
            {data?.categories?.map((d, index) => (
              <div
                key={index}
                className="col-md-3 mb-2" // Ensures 4 items per row
                onClick={() => handleItemClick(d)}
              >
                <div
                  className={`border rounded cursor-pointer ${
                    selectedItem === d ? "border-primary" : ""
                  }`}
                  style={{
                    padding: "0",
                    margin: "0",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%", // Ensure height adjusts based on content
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "150px", // Adjust the height as needed
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <img
                      src={"https://via.placeholder.com/150"}
                      alt={d.category}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        display: "block",
                      }}
                    />
                  </div>
                  <div
                    className="text-center"
                    style={{ padding: "0.5rem", width: "100%" }}
                  >
                    {d.category}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default StepTwoForm;
