// import {
//   closestCorners,
//   DndContext,
//   MouseSensor,
//   TouchSensor,
//   useSensor,
//   useSensors,
// } from "@dnd-kit/core";
// import {
//   SortableContext,
//   useSortable,
//   verticalListSortingStrategy,
// } from "@dnd-kit/sortable";
// import { CSS } from "@dnd-kit/utilities";
// import ActionButton from "components/common/ActionButton";
// import useQuery from "hooks/useQuery";
// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   Card,
//   Col,
//   FloatingLabel,
//   Form,
//   Row,
//   Spinner,
//   Table,
// } from "react-bootstrap";
// import { useFieldArray, useFormContext } from "react-hook-form";
// import { useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";

// const SortableItem = ({ id, children }) => {
//   const { attributes, listeners, setNodeRef, transform, transition } =
//     useSortable({ id });

//   const style = {
//     transform: CSS.Transform.toString(transform),
//     transition,
//   };

//   return (
//     <tr ref={setNodeRef} style={style}>
//       <td
//         {...listeners}
//         {...attributes}
//         style={{
//           cursor: "grab",
//           textAlign: "center",
//           width: "10px",
//           // paddingTop: "15px",
//         }}
//       >
//         ☰
//       </td>
//       {children}
//     </tr>
//   );
// };

// const MediaInfo = () => {
//   const {
//     register,
//     getValues,
//     control,
//     watch,
//     setValue,
//     formState: { errors },
//   } = useFormContext();
//   const { pathname } = useLocation();
//   const navigate = useNavigate();

//   const {
//     fields: mediaInfos,
//     append: appendVideo,
//     remove: removeVideo,
//     move,
//   } = useFieldArray({
//     control,
//     name: "mediaInfos",
//   });

//   const sensors = useSensors(
//     useSensor(MouseSensor),
//     useSensor(TouchSensor, {
//       activationConstraint: {
//         delay: 250,
//         tolerance: 5,
//       },
//     })
//   );

//   const handleDragEnd = (event) => {
//     const { active, over } = event;
//     if (active.id !== over.id) {
//       const oldIndex = mediaInfos.findIndex((item) => item.id === active.id);
//       const newIndex = mediaInfos.findIndex((item) => item.id === over.id);
//       move(oldIndex, newIndex);
//     }
//   };

//   return (
//     <>
//       {/* <Card.Header as="h6" className="bg-body-tertiary mb-2">
//         Media information
//       </Card.Header> */}
//       <Row className="gx-2 gy-2">
//         <Col md="12">
//           <Form.Group controlId="formFileLg" className="mb-3">
//             <Form.Label>Images</Form.Label>
//             <Form.Control type="file" size="lg" />
//           </Form.Group>
//         </Col>
//         <Card.Header as="h6" className="bg-body-tertiary">
//           Videos
//         </Card.Header>
//         <Col md="12">
//           <Row className="gx-2 gy-1">
//             <DndContext
//               sensors={sensors}
//               collisionDetection={closestCorners}
//               onDragEnd={handleDragEnd}
//             >
//               <SortableContext
//                 items={mediaInfos.map((item) => item.id)}
//                 strategy={verticalListSortingStrategy}
//               >
//                 <Table responsive>
//                   <thead className="bg-200">
//                     <tr>
//                       <th scope="col" className="w-0 fs-10 text-center">
//                         Order
//                       </th>

//                       <th scope="col" className="fs-10">
//                         Link
//                       </th>

//                       <th scope="col" className="fs-10">
//                         Preview
//                       </th>
//                       <th scope="col"></th>
//                       <th scope="col"></th>
//                       <th
//                         scope="col"
//                         style={{ padding: "9px", width: "30px" }}
//                         className=" text-end"
//                       >
//                         <ActionButton
//                           icon="plus"
//                           variant="success"
//                           title="Add"
//                           className="px-1 py-0"
//                           onClick={() => appendVideo({})}
//                         />
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {mediaInfos.map((item, index) => (
//                       <SortableItem key={item.id} id={item.id}>
//                         <td style={{ padding: "5px" }}>
//                           <Form.Group controlId="formGroupName">
//                             <Form.Control
//                               type="text"
//                               placeholder="Link"
//                               isInvalid={
//                                 !!errors.mediaInfos?.[index]?.mediaInfo
//                               }
//                               {...register(`mediaInfos[${index}].mediaInfo`)}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                               {errors.mediaInfos?.[index]?.mediaInfo?.message}
//                             </Form.Control.Feedback>
//                           </Form.Group>
//                         </td>
//                         <td
//                           style={{ padding: "5px" }}
//                           className="text-center"
//                         ></td>
//                         <td></td>
//                         <td></td>

//                         <td style={{ padding: "5px" }} className="text-center">
//                           <ActionButton
//                             icon="trash-alt"
//                             title="Delete"
//                             variant="danger"
//                             className="px-1 py-0"
//                             onClick={() => removeVideo(index)}
//                           />
//                         </td>
//                       </SortableItem>
//                     ))}
//                   </tbody>
//                 </Table>
//               </SortableContext>
//             </DndContext>
//           </Row>
//         </Col>
//       </Row>
//     </>
//   );
// };

// export default MediaInfo;

import {
  closestCorners,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import axios from "axios";
import ActionButton from "components/common/ActionButton";
import LightBoxLatestExample from "components/common/LightBoxLatestExample";
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
  Image,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FaImages } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SortableItem = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <tr ref={setNodeRef} style={style}>
      <td
        {...listeners}
        {...attributes}
        style={{
          cursor: "grab",
          textAlign: "center",
          width: "10px",
        }}
      >
        ☰
      </td>
      {children}
    </tr>
  );
};

const MediaInfo = () => {
  const {
    register,
    getValues,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    fields: mediaInfos,
    append: appendMedia,
    remove: removeMedia,
    move,
  } = useFieldArray({
    control,
    name: "mediaInfos",
  });

  const [previews, setPreviews] = useState({});
  const [imagePreviews, setImagePreviews] = useState([]);

  const [imagesList, setImagesList] = useState({});
  const [imagesPreviewList, setImagesPreviewList] = useState({});
  const [serverImagesList, setServerImagesList] = useState({});

  const Dropzone = ({ onDrop, index }) => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: {
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
      },
      multiple: true,
      onDrop: (acceptedFiles) => onDrop(acceptedFiles, index),
    });

    return (
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <div style={{ padding: "5px" }}>
          <FaImages size={25} />
        </div>
      </div>
    );
  };

  const uploadImages = async (files) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file); // 'files[]' is the key name on the backend
    });

    try {
      const response = await axios.post("/Catalogue/ProductFiles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Assuming your backend returns a list of URLs
      return response?.data;
    } catch (error) {
      console.error("Error uploading images:", error);
      return [];
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles, index) => {
      // Create object URLs for preview
      const newImages = acceptedFiles.map((file) => URL.createObjectURL(file));

      // Update image preview list
      setImagesPreviewList((prevImagesList) => {
        const updatedImagesPreview = {
          ...prevImagesList,
          [index]: [...(prevImagesList[index] || []), ...newImages],
        };

        return updatedImagesPreview;
      });

      // Upload images and get the URLs
      const response = await uploadImages(acceptedFiles);

      // Filter out null values from response
      const destinations = response
        ?.map((item) => item.destination)
        .filter((url) => url !== null);

      // Get the current length of mediaInfos to determine the starting sort order
      const currentMediaInfosLength = mediaInfos.length;

      // Create a new mediaInfo object for each destination and append it to mediaInfos
      destinations.forEach((destination, index) => {
        appendMedia({
          mediaInfo: destination,
          contentType: "image",
          status: true,
          sortOrder: currentMediaInfosLength + index + 1, // Set sort order
        });
      });
    },
    [setValue, uploadImages, getValues, appendMedia, mediaInfos.length]
  );

  const removeImage = (rowIndex, imageIndex) => {
    setImagesPreviewList((prevImagesList) => {
      // Ensure the rowIndex exists and is valid
      if (!prevImagesList[rowIndex]) {
        console.warn(`Row index ${rowIndex} not found.`);
        return prevImagesList;
      }

      // Filter out the image at imageIndex
      const updatedImages = prevImagesList[rowIndex].filter(
        (_, index) => index !== imageIndex
      );

      // Update the form value
      // setValue(`mediaInfos[${rowIndex}].imagesPreview`, updatedImages);

      // Return the updated state
      return {
        ...prevImagesList,
        [rowIndex]: updatedImages,
      };
    });
  };

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const updateSortOrder = () => {
    // Get the current value of mediaInfos (make sure it's up to date)
    const updatedMediaInfos = getValues("mediaInfos");

    // Iterate over the array and set the sortOrder for each item
    updatedMediaInfos.forEach((_, index) => {
      setValue(`mediaInfos[${index}].sortOrder`, index);
    });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = mediaInfos.findIndex((item) => item.id === active.id);
      const newIndex = mediaInfos.findIndex((item) => item.id === over.id);
      move(oldIndex, newIndex);

      updateSortOrder();
    }
  };

  const getContentType = (url) => {
    if (url.match(/\.(jpeg|jpg|png)$/)) return "image";
    if (url.match(/\.(mp4|webm)$/)) return "video";
    return "unknown";
  };

  const handleAppend = () => {
    appendMedia({ contentType: "video", status: true });

    // Delay the sortOrder update to ensure the new item is appended first
    setTimeout(() => {
      updateSortOrder();
    }, 0);
  };

  const handleUrlChange = (index) => {
    const url = getValues(`mediaInfos[${index}].mediaInfo`);
    if (url) {
      const type = getContentType(url);
      setValue(`mediaInfos[${index}].contentType`, type);
      setPreviews((prev) => ({ ...prev, [index]: { type, url } }));
    } else {
      // Remove preview if URL is cleared
      setPreviews((prev) => {
        const updatedPreviews = { ...prev };
        delete updatedPreviews[index];
        return updatedPreviews;
      });
    }
  };

  useEffect(() => {
    mediaInfos.forEach((_, index) => handleUrlChange(index));
  }, [mediaInfos]);

  return (
    <>
      <Row className="gx-2 gy-2">
        <Card.Header as="h6" className="bg-body-tertiary">
          Images
        </Card.Header>
        <Col md="12">
          <Dropzone onDrop={handleDrop} />
        </Col>

        <Col md="12">
          <MyImageGallery
            imagesPreviewList={imagesPreviewList}
            removeImage={removeImage}
          />
        </Col>
        <Card.Header as="h6" className="bg-body-tertiary">
          Videos
        </Card.Header>
        <Col md="12">
          <Row className="gx-2 gy-1">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCorners}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={mediaInfos.map((item) => item.id)}
                strategy={verticalListSortingStrategy}
              >
                <Table responsive>
                  <thead className="bg-200">
                    <tr>
                      <th scope="col" className="w-0 fs-10 text-center">
                        Order
                      </th>
                      <th scope="col" className="fs-10">
                        Link
                      </th>
                      <th
                        scope="col"
                        className="fs-10 text-center"
                        style={{ width: "30px" }}
                      >
                        Preview
                      </th>
                      <th
                        scope="col"
                        style={{ padding: "9px", width: "30px" }}
                        className="text-end"
                      >
                        <ActionButton
                          icon="plus"
                          variant="success"
                          title="Add"
                          className="px-1 py-0"
                          onClick={handleAppend}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {mediaInfos.map(
                      (item, index) =>
                        item.contentType === "video" && (
                          <SortableItem key={item.id} id={item.id}>
                            <td style={{ padding: "5px" }}>
                              <Form.Group controlId={`formGroupName-${index}`}>
                                <Form.Control
                                  type="text"
                                  placeholder="Link"
                                  // isInvalid={
                                  //   !!errors.mediaInfos?.[index]?.mediaInfo
                                  // }
                                  {...register(
                                    `mediaInfos[${index}].mediaInfo`,
                                    {
                                      onChange: () => handleUrlChange(index),
                                    }
                                  )}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {/* {
                                    errors.mediaInfos?.[index]?.mediaInfo
                                      ?.message
                                  } */}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </td>
                            <td
                              style={{ padding: "5px" }}
                              className="text-center"
                            >
                              {previews[index]?.type === "video" && (
                                <video width="200" height="auto" controls>
                                  <source
                                    src={previews[index]?.url}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              )}
                            </td>
                            <td
                              style={{ padding: "5px" }}
                              className="text-center"
                            >
                              <ActionButton
                                icon="trash-alt"
                                title="Delete"
                                variant="danger"
                                className="px-1 py-0"
                                onClick={() => removeMedia(index)}
                              />
                            </td>
                          </SortableItem>
                        )
                    )}
                  </tbody>
                </Table>
              </SortableContext>
            </DndContext>
          </Row>
        </Col>
      </Row>
    </>
  );
};

const MyImageGallery = ({
  index,
  imagesPreviewList,
  serverImagesList,
  removeImage,
}) => {
  // Combine the preview images and server images
  const combinedImages = [
    ...(imagesPreviewList[index] || []),
    // ...(serverImagesList[index]?.map(
    //   (image) => `data:image/png;base64,${image?.fileBase64}`
    // ) || []),
  ];

  return (
    <LightBoxLatestExample images={combinedImages}>
      {(setImgIndex) => (
        <Row className="g-2">
          {combinedImages.map((image, imgIndex) => (
            <Col xs={4} md={4} key={imgIndex} className="position-relative">
              <Image
                src={image}
                fluid
                rounded
                className="cursor-pointer"
                onClick={() => setImgIndex(imgIndex)}
              />
              <Button
                variant="danger"
                size="sm"
                onClick={() => removeImage(index, imgIndex)}
                className="position-absolute top-0 end-0"
                style={{ padding: "0px 1px 0px 1px" }}
              >
                &times;
              </Button>
            </Col>
          ))}
        </Row>
      )}
    </LightBoxLatestExample>
  );
};

export default MediaInfo;
