import React, { useState, useEffect, useMemo } from "react";
import { Button, Col, Form, Image, Modal, Row, Spinner } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import Select from "react-select";
import * as ReactIcons from "react-icons/bs";
import * as ReactIconsFa from "react-icons/fa6";
import * as ReactIconsMd from "react-icons/md";
import * as ReactIconsGi from "react-icons/gi";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchCategories } from "hooks/useFetchCategories";
import { useLocation } from "react-router-dom";
import { CategoriesAPI } from "api";
import { FixedSizeList as List } from "react-window";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import Flex from "components/common/Flex";
import { BiCloudUpload } from "react-icons/bi";
import { useDropzone } from "react-dropzone";
import { FaImage } from "react-icons/fa";
import QuillEditor from "components/common/QuillEditor";

import noPhoto from "assets/img/no-photo.jpg";
import FalconLightBox from "components/common/FalconLightBox";

const AddCategoryModal = ({
  setIsOpenModal,
  isOpenModal,
  selectedNode,
  setSelectedNode,
}) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [cLevel, setCLevel] = useState(1); // Default to level 1
  const queryClient = useQueryClient();
  // const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const [fileUrl, setFileUrl] = useState("");
  const [formData, setFormData] = useState({});
  const [file, setFile] = useState(null);

  const { data: categoriesData } = useFetchCategories();
  const [description, setDescription] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/*": [".jpeg", ".png", ".jpg"],
    },
    onDrop: async (acceptedFiles) => {
      const uploadedFile = acceptedFiles[0];
      const uploadedFileUrl = await uploadFile(uploadedFile);
      setFileUrl(uploadedFileUrl);
      setFile(
        Object.assign(uploadedFile, {
          preview: URL.createObjectURL(uploadedFile),
        })
      );
    },
  });

  const schema = yup
    .object()
    .shape({
      name: yup
        .string()
        .transform((value) => (value ? value.trim() : value)) // Trim whitespace from value
        .required("Name is required")
        .test(
          "unique-name-under-parent",
          "The name already exists at the root level or under the selected parent with the same level.",
          function (value) {
            // Ensure value is not null or undefined
            if (!value) {
              return true; // Skip if no value is provided
            }

            // Extract parent, cLevel, and currentId from the current form context
            const { parent, cLevel, id } = this.parent;

            // Convert parent and cLevel to numbers
            const parentId = parent ? Number(parent) : null;
            const level = cLevel ? Number(cLevel) : 1;

            // Retrieve the list of existing categories
            const existingCategories = categoriesData?.data?.data || [];

            if (parentId === null) {
              // Case 1: Adding as a root category (no parent selected)
              const isUniqueRoot = !existingCategories.some(
                (item) =>
                  item.id !== id && // Exclude current item if updating
                  Number(item.cLevel) === 1 && // Ensure cLevel is treated as a number
                  item.name?.trim().toLowerCase() ===
                    value?.trim().toLowerCase() // Case-insensitive name comparison
              );

              return isUniqueRoot; // Pass if no duplicate found at root level
            } else {
              // Case 2: Adding as a child category under a selected parent
              const isDuplicateUnderParent = existingCategories.some((item) => {
                const itemParent = Number(item.parent);
                const itemCLevel = Number(item.cLevel);
                const itemName = item.name?.trim().toLowerCase();

                return (
                  item.id !== id && // Exclude current item if updating
                  itemParent === parentId &&
                  itemCLevel === level &&
                  itemName === value.trim().toLowerCase()
                );
              });

              return !isDuplicateUnderParent; // Pass if no duplicate found under the same parent and level
            }
          }
        ),

      icons: yup.string(),
      url: yup.string(),
      logo: yup.string(),
      status: yup.boolean(),
      parent: yup.string().nullable(),
      description: yup.string(),
      sortOrder: yup.string().required("Display order is required"),
      // cLevel: yup.string(),
    })
    .required();

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      icons: "",
      url: "",
      logo: "",
      status: false,
      parent: null,
      cLevel: "",
      description: "",
      sortOrder: "",
    },
  });
  const {
    handleSubmit,
    reset,
    setValue,
    register,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;

  const nameWatch = watch("name");
  const urlWatch = watch("url");
  const skuWatch = watch("sku");

  const maxCharactersLengthURL = 255;
  const maxCharactersLengthName = 255;
  const maxCharactersLengthSKU = 255;

  const CombinedIcons = {
    ...ReactIcons,
    ...ReactIconsFa,
    ...ReactIconsMd,
    ...ReactIconsGi,
  };
  // const CombinedIcons = { ...ReactIcons };

  const iconNames = Object.keys(CombinedIcons);
  // const iconOptions = iconNames.map((iconName) => ({
  //   value: iconName,
  //   label: (
  //     <div className="flex items-center gap-2">
  //       {React.createElement(CombinedIcons[iconName])} {iconName}
  //     </div>
  //   ),
  // }));

  const [inputValue, setInputValue] = useState("");

  // Filter icons based on search input
  const filteredIcons = useMemo(
    () =>
      iconNames
        .filter((iconName) =>
          iconName.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((iconName) => ({
          value: iconName,
          label: (
            <div className="flex items-center gap-2">
              {React.createElement(CombinedIcons[iconName])} {iconName}
            </div>
          ),
        })),
    [inputValue]
  );

  const MenuList = (props) => {
    const { options, children, maxHeight, getValue } = props;
    const height = 35; // Height for each row
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
        width="100%"
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  };

  // const handleFileChange = (event) => {
  //   const uploadedFile = event.target.files[0];
  //   setFile(uploadedFile);
  //   if (uploadedFile) {
  //     const preview = URL.createObjectURL(uploadedFile);
  //     setPreviewUrl(preview); // Set the preview URL
  //   } else {
  //     setPreviewUrl(null);
  //   }
  // };

  // const handleFileChange = async (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) {
  //     setFile(selectedFile);
  //     const uploadedFileUrl = await uploadFile(selectedFile);
  //     setFileUrl(uploadedFileUrl);
  //   }
  // };

  // const handleFileChange = async (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) {
  //     setFile(selectedFile);

  //     // Generate preview URL
  //     const preview = URL.createObjectURL(selectedFile);
  //     setPreviewUrl(preview);

  //     // Upload file
  //     const uploadedFileUrl = await uploadFile(selectedFile);
  //     setFileUrl(uploadedFileUrl);

  //     // Clean up URL object when component unmounts
  //     return () => URL.revokeObjectURL(preview);
  //   } else {
  //     setPreviewUrl(null);
  //   }
  // };

  useEffect(() => {
    // Register fields with react-hook-form
    register("description");
  }, [register]);

  useEffect(() => {
    if (selectedNode) {
      setDescription(selectedNode?.description);
      setValue("description", selectedNode.description || "");
    }
  }, [selectedNode, setValue]);

  // Update form values when local state changes
  useEffect(() => {
    setValue("description", description);
  }, [description, setValue]);

  useEffect(() => {
    if (categoriesData) {
      const hierarchicalData = createHierarchy(categoriesData?.data?.data);
      setData(hierarchicalData);
    }
  }, [categoriesData]);

  useEffect(() => {
    if (data.length > 0) {
      const flatOptions = flattenOptions(data);
      setOptions(flatOptions);
    }
  }, [data]);

  const getNodeDepth = (nodeId, nodes, currentDepth = 1) => {
    for (const node of nodes) {
      if (node.id === nodeId) {
        return currentDepth;
      }
      if (node.children && node.children.length > 0) {
        const depth = getNodeDepth(nodeId, node.children, currentDepth + 1);
        if (depth) {
          return depth;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    if (selectedOption) {
      setValue("parent", selectedOption.value);
      const selectedNodeDepth = getNodeDepth(selectedOption.value, data);

      if (selectedNodeDepth) {
        // Set cLevel to the depth of the selected node + 1
        setValue("cLevel", selectedNodeDepth + 1);
        setCLevel(selectedNodeDepth + 1);
      } else {
        // Default to cLevel 1 if no node is found
        setCLevel(1);
      }
    } else {
      setCLevel(1); // Default to cLevel 1 if no node is selected
    }
  }, [selectedOption, data]);

  const handleClose = () => {
    reset();
    setIsOpenModal(false);
    setSelectedNode(null);
    setSelectedOption(null);
    setPreviewUrl(null);
    setFile(null);
    setDescription(null);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      const response = await axios.post("/Catalogue/CategoryFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);

      // Assuming the response contains the URL of the uploaded file
      return response.data[0].destination;
    } catch (error) {
      console.error("File upload failed:", error);
      toast.error("File upload failed");
      return "";
    }
  };

  const onSubmit = async (formData) => {
    // let fileUrl = "";
    // if (file) {
    //   fileUrl = await uploadFile(file);
    // }

    // console.log(formData);

    const myData = {
      ...formData,
      id: formData.id ? formData.id : 0,
      cLevel,
      parent: selectedOption ? selectedOption.value : 0,
      logo: !fileUrl && formData.logo ? formData.logo : fileUrl,
    };
    // console.log(myData);

    // Handle form submission logic here

    try {
      if (selectedNode) {
        await CategoriesAPI.post(myData); // Assuming you're updating the category
        toast.success("Category Updated Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      } else {
        await CategoriesAPI.post(myData);
        toast.success("Category Created Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      }

      // ------- Get all object keys from data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      queryClient.invalidateQueries(["categories"]); // Invalidate and refetch
      handleClose();
    } catch (error) {
      toast.error("Something Went Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedNode) {
      reset(selectedNode);
      if (selectedNode.parent) {
        const parentOption = options.find(
          (option) => option.value === selectedNode.parent
        );
        setSelectedOption(parentOption || null);
      } else {
        setSelectedOption(null);
      }

      // Set cLevel based on the level of the modalContent
      const contentNodeDepth = getNodeDepth(selectedNode.parent, data);
      if (contentNodeDepth) {
        setCLevel(contentNodeDepth + 1);
      } else {
        setCLevel(1); // Default level if no parent is found
      }
    } else {
      // Reset form if no modalContent is provided
      reset();
      setSelectedOption(null);
      setCLevel(1);
    }
  }, [selectedNode, setValue, options, data, reset]);

  useEffect(() => {
    if (!isOpenModal) {
      reset({
        name: "",
        icons: "",
        url: "",
        logo: "",
        status: false,
        parent: null,
        description: "",
        sortOrder: "",
      });
    }
  }, [isOpenModal, reset]);

  const handleInputFocus = () => {
    clearErrors("icons");
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      // borderColor: errors.icons ? "red" : provided.borderColor,
      borderColor: errors.icons ? "red" : "#dbdbdb",
      "&:hover": {
        borderColor: errors.icons ? "red" : provided["&:hover"].borderColor,
      },
    }),
  };

  // if (loading) {
  //   return <Spinner variant="primary" animation="border" />;
  // }

  return (
    <Modal
      size="lg"
      show={isOpenModal}
      onHide={handleClose}
      contentClassName="border"
    >
      {/* {loading && (
        <div className="loading-overlay d-flex align-items-center justify-content-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )} */}
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            className="bg-body-tertiary border-bottom-0"
          >
            <Modal.Title as="h5">Category</Modal.Title>
          </Modal.Header>
          {loading ? (
            // <div className="loading-overlay d-flex align-items-center justify-content-center">
            //   <Spinner animation="border" variant="primary" />
            // </div>
            <div className="d-flex justify-content-center align-items-center">
              <Button variant="falcon-default" size="sm" className="fs-10">
                Loading...
              </Button>
            </div>
          ) : (
            <Modal.Body className="px-3 py-1">
              <Row className="gx-2 gy-2">
                <Col md="6">
                  <Form.Group controlId="name">
                    {/* <Form.Label className="fs-10">Name</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      title="Name"
                      maxLength={maxCharactersLengthName}
                      isInvalid={!!errors.name}
                      {...register("name")}
                    />
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Control.Feedback
                        type="invalid"
                        className="d-inline w-75"
                      >
                        {errors.name?.message}
                      </Form.Control.Feedback>
                      <small className="fs-10 text-muted">
                        {`${nameWatch?.length} / ${maxCharactersLengthName}`}
                      </small>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="parent">
                    {/* <Form.Label className="fs-10">Parent</Form.Label> */}
                    <div title="Select a parent Category">
                      <Select
                        options={options}
                        isClearable
                        isDisabled={!!selectedNode}
                        value={selectedOption}
                        onChange={setSelectedOption}
                        placeholder="Select a parent category"
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            paddingLeft: `${state.data.level * 10}px`,
                          }),
                          control: (provided) => ({
                            ...provided,
                            border: "1px solid #dbdbdb",
                          }),
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="" controlId="url">
                    {/* <Form.Label className="fs-10">URL</Form.Label> */}
                    <Form.Control
                      type="text"
                      placeholder="URL"
                      title="URL"
                      maxLength={maxCharactersLengthURL}
                      isInvalid={!!errors.url}
                      {...register("url")}
                    />

                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Control.Feedback
                        type="invalid"
                        className="d-inline w-75"
                      >
                        {errors.url?.message}
                      </Form.Control.Feedback>
                      <small className="fs-10 text-muted">
                        {`${urlWatch?.length || 0} / ${maxCharactersLengthURL}`}
                      </small>
                    </div>
                  </Form.Group>
                </Col>
                <Col md="6">
                  {/* <Form.Label className="fs-10">Sort Order</Form.Label> */}

                  <Form.Group className="" controlId="sortOrder">
                    <Form.Control
                      type="number"
                      placeholder="Display Order"
                      title="Display Order"
                      isInvalid={!!errors.sortOrder}
                      {...register("sortOrder", {
                        // Additional validation
                        validate: (value) =>
                          value > 0 || "SortOrder must be a positive number", // Custom validation message
                      })}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Allow only positive integers or empty
                        if (value === "" || /^[0-9]\d*$/.test(value)) {
                          e.target.value = value; // Set the value if it's valid
                        } else {
                          e.target.value = ""; // Reset if invalid
                        }
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.sortOrder?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                {/* <Col md="6">
                  <Form.Group className="" controlId="sku">
                    <Form.Control
                      type="text"
                      placeholder="SKU"
                      title="SKU"
                      maxLength={maxCharactersLengthSKU}
                      isInvalid={!!errors.SKU}
                      {...register("sku")}
                    />
                    <small className="fs-10 d-flex justify-content-end">
                      {`${skuWatch?.length || 0} / ${maxCharactersLengthSKU}`}
                    </small>
                    <Form.Control.Feedback type="invalid">
                      {errors.sku?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col> */}

                <Col md="6">
                  <div className="d-flex align-items-center justify-content-between mt-1">
                    {/* <Form.Label className="fs-10">Sort Order</Form.Label> */}

                    <Form.Check
                      type="checkbox"
                      id="statusSwitch"
                      label="Status"
                      defaultChecked={!selectedNode}
                      {...register("status")}
                    />
                    <Form.Check
                      type="checkbox"
                      id="popularCheckbox"
                      label="Popular"
                      defaultChecked={!selectedNode}
                      {...register("isPopular")}
                    />
                    <Form.Check
                      type="checkbox"
                      id="hotCheckbox"
                      label="Hot"
                      defaultChecked={!selectedNode}
                      {...register("isHot")}
                    />
                    <Form.Check
                      type="checkbox"
                      id="newCheckbox"
                      label="New"
                      defaultChecked={!selectedNode}
                      {...register("isNew")}
                    />
                  </div>
                </Col>

                {/* <Col md="6">
                  <Form.Group controlId="icon">
                    <div title="Select an icon">
                      <Select
                        name="icons"
                        styles={customStyles}
                        options={iconOptions}
                        isClearable
                        onFocus={handleInputFocus}
                        placeholder="Select an icon"
                        onChange={(selectedOption) => {
                          // console.log("Selected icon:", selectedOption);
                          setValue(
                            "icons",
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                        value={iconOptions.find(
                          (option) => option.value === methods.watch("icons")
                        )}
                        className={`basic-single ${
                          errors.icons ? "is-invalid" : ""
                        }`}
                      />
                    </div>

                    {errors.icons && (
                      <Form.Control.Feedback type="invalid">
                        {errors.icons.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col> */}
              </Row>

              {/* <Row className="gx-2 mb-1">
                <Col md="6">
                  <Form.Group controlId="parent">
                    <Form.Label className="fs-10">Parent</Form.Label>
                    <Select
                      options={options}
                      isClearable
                      isDisabled={!!selectedNode}
                      value={selectedOption}
                      onChange={setSelectedOption}
                      placeholder="Select a category"
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          paddingLeft: `${state.data.level * 10}px`,
                        }),
                        // control: (provided) => ({
                        //   ...provided,
                        //   marginBottom: "1rem",
                        // }),
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="" controlId="url">
                    <Form.Label className="fs-10">URL</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="URL"
                      maxLength={maxCharactersLengthURL}
                      isInvalid={!!errors.url}
                      {...register("url")}
                    />
                    <small className="fs-10 d-flex justify-content-end">
                      {`${urlWatch?.length || 0} / ${maxCharactersLengthURL}`}
                    </small>
                    <Form.Control.Feedback type="invalid">
                      {errors.url?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row> */}

              <Form.Group className="mb-1" controlId="url">
                {/* <Form.Label>Description</Form.Label> */}
                <QuillEditor
                  setValue={setDescription}
                  value={description}
                  placeholder={"Description"}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mt-2">
                {/* <Form.Label>Logo</Form.Label> */}
                {/* <div className="d-flex justify-content-between"> */}
                <div className="mb-1 d-flex gap-4 align-items-center">
                  {/* <div {...getRootProps({ className: " cursor-pointer" })}>
                    <input {...getInputProps({ multiple: false })} />
                    <Flex justifyContent="start">
                      <Button
                        size="sm"
                        variant="falcon-default"
                        className="me-2 mb-1"
                      >
                        <FaImage size={15} className="mx-1" />
                        Select Logo
                      </Button>
                    </Flex>
                  </div> */}

                  <Col md="4">
                    <Form.Group controlId="icon">
                      <div title="Select an icon">
                        <Select
                          name="icons"
                          styles={customStyles}
                          options={filteredIcons}
                          components={{ MenuList }}
                          isClearable
                          onFocus={handleInputFocus}
                          placeholder="Select an icon"
                          onChange={(selectedOption) => {
                            // console.log("Selected icon:", selectedOption);
                            setValue(
                              "icons",
                              selectedOption ? selectedOption.value : ""
                            );
                          }}
                          value={filteredIcons.find(
                            (option) => option.value === methods.watch("icons")
                          )}
                          className={`basic-single ${
                            errors.icons ? "is-invalid" : ""
                          }`}
                        />
                      </div>

                      {errors.icons && (
                        <Form.Control.Feedback type="invalid">
                          {errors.icons.message}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>

                  <div {...getRootProps({ className: "dropzone-area py-1" })}>
                    <input {...getInputProps({ multiple: false })} />
                    <Flex justifyContent="center">
                      <FaImage size={27} className="mx-1" />
                      <p className="fs-9 mb-0 text-700"> Select Image</p>
                    </Flex>
                  </div>

                  <div>
                    {file ? (
                      <Flex
                        alignItems="center"
                        // className="py-3"
                        key={file.path}
                      >
                        <FalconLightBox image={file.preview}>
                          <Image
                            rounded
                            width={60}
                            style={{
                              border: "1px solid #bababa",
                            }}
                            src={file.preview}
                            alt={file.path}
                          />
                        </FalconLightBox>
                      </Flex>
                    ) : selectedNode && selectedNode?.logo ? (
                      <Flex alignItems="center">
                        <FalconLightBox
                          image={`data:image/${selectedNode?.logo
                            ?.split(".")
                            .pop()};base64,${
                            selectedNode?.logoFile?.fileBase64
                          }`}
                        >
                          <Image
                            rounded
                            width={60}
                            style={{
                              border: "1px solid #bababa",
                            }}
                            src={`data:image/${selectedNode?.logo
                              ?.split(".")
                              .pop()};base64,${
                              selectedNode?.logoFile?.fileBase64
                            }`}
                          />
                        </FalconLightBox>
                      </Flex>
                    ) : (
                      <Flex alignItems="center">
                        <Image
                          rounded
                          width={60}
                          height={40}
                          style={{
                            border: "1px solid #bababa",
                          }}
                          src={noPhoto}
                          alt={noPhoto}
                        />
                      </Flex>
                    )}
                  </div>
                  {/* </div> */}
                </div>

                {/* <Form.Control.Feedback type="invalid">
                  {errors.logo?.message}
                </Form.Control.Feedback> */}
              </Form.Group>

              {/* <div className="d-flex align-items-center justify-content-between mt-1">
                <Col md="3">
                  <Form.Group controlId="icon">
                    <div title="Select an icon">
                      <Select
                        name="icons"
                        styles={customStyles}
                        options={iconOptions}
                        isClearable
                        onFocus={handleInputFocus}
                        placeholder="Select an icon"
                        onChange={(selectedOption) => {
                          // console.log("Selected icon:", selectedOption);
                          setValue(
                            "icons",
                            selectedOption ? selectedOption.value : ""
                          );
                        }}
                        value={iconOptions.find(
                          (option) => option.value === methods.watch("icons")
                        )}
                        className={`basic-single ${
                          errors.icons ? "is-invalid" : ""
                        }`}
                      />
                    </div>

                    {errors.icons && (
                      <Form.Control.Feedback type="invalid">
                        {errors.icons.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </div> */}
              {/* <div className="d-flex align-items-center justify-content-between mt-1">

                <Form.Check
                  type="checkbox"
                  id="statusSwitch"
                  label="Status"
                  defaultChecked={!selectedNode}
                  {...register("status")}
                />
                <Form.Check
                  type="checkbox"
                  id="popularCheckbox"
                  label="Popular"
                  defaultChecked={!selectedNode}
                  {...register("isPopular")}
                />
                <Form.Check
                  type="checkbox"
                  id="hotCheckbox"
                  label="Hot"
                  defaultChecked={!selectedNode}
                  {...register("isHot")}
                />
                <Form.Check
                  type="checkbox"
                  id="newCheckbox"
                  label="New"
                  defaultChecked={!selectedNode}
                  {...register("isNew")}
                />
              </div> */}
            </Modal.Body>
          )}

          <Modal.Footer className="bg-body-tertiary px-x1 border-top-0">
            <div onClick={handleClose} className="px-4 mx-0 cursor-pointer">
              Cancel
            </div>
            <Button variant="primary" type="submit" className="px-4 mx-0">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
};

const flattenOptions = (nodes, level = 1) => {
  return nodes.reduce((acc, node) => {
    const label = `${">".repeat(level)} ${node.name}`;
    acc.push({ value: node.id, label, parent: node.parent, level });

    if (node.children && node.children.length > 0) {
      acc.push(...flattenOptions(node.children, level + 1));
    }

    return acc;
  }, []);
};

const createHierarchy = (data) => {
  const lookup = {};
  const result = [];

  data.forEach((item) => {
    lookup[item.id] = { ...item, children: [] };
  });

  data.forEach((item) => {
    const { id, parent } = item;
    if (parent === 0) {
      result.push(lookup[id]);
    } else if (lookup[parent]) {
      lookup[parent].children.push(lookup[id]);
    }
  });

  return result;
};

export default AddCategoryModal;
