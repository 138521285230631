import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import BreadCrumb from "components/breadcrumb/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
// import { customSettings } from "utils/customConfig";
import Details from "./details/Details";
import ProductGeneralInfo from "./general-info/ProductGeneralInfo";
import MediaInfo from "./media-info/MediaInfo";
import PricingAndStockInfo from "./pricing-and-stock/PricingAndStockInfo";
import ShippingAndWarrantyInfo from "./shipping-and-warranty/ShippingAndWarrantyInfo";
import Specifications from "./specifications/Specifications";
import Variants from "./variants/Variants";
import { ProductsAPI } from "api";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import RelatedProducts from "./related-products/RelatedProducts";
import { useTranslation } from "react-i18next";

const schema = yup.object({
  categoryId: yup.string(),
  name: yup.string().required("Name is required"),
  description: yup.string(),
  highlights: yup.string(),
  insideBox: yup.string(),
  type: yup.string(),
  tags: yup.array().of(yup.string()),
  salesPrice: yup.string(),
  cost: yup.string(),
  mediaInfos: yup.array().of(
    yup.object().shape({
      mediaInfo: yup.string(),
      imagesPreview: yup.array().of(yup.string()).nullable(),
      contentType: yup.string(),
      sortOrder: yup.string(),
      status: yup.boolean(),
    })
  ),
  relatedProducts: yup.array().of(
    yup.object().shape({
      relatedProductId: yup.string(),
      sortOrder: yup.string(),
      status: yup.boolean(),
    })
  ),
  weight: yup.string(),
  weightUOM: yup.string(),
  length: yup.string(),
  width: yup.string(),
  height: yup.string().required("Height is required"),
  volumeUOM: yup.string(),
  warranty: yup.string(),
  warrantyType: yup.string(),
  warrantyPeriod: yup.string(),
  warrantyPolicy: yup.string(),
  specifications: yup.array().of(
    yup.object().shape({
      fieldSetId: yup.string(),
      fieldId: yup.string(),
      fieldValue: yup.string(),
      status: yup.boolean(),
    })
  ),
  status: yup.boolean(),
});
// .required();

const AddProduct = () => {
  const { t } = useTranslation();
  const [key, setKey] = useState("basicInfo");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      mediaInfos: [
        { contentType: "video", sortOrder: 0, status: true, mediaInfo: "" },
      ],
      relatedProducts: [{}],
    },
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data) => {
    let updatedRelatedProducts = data?.relatedProducts.map((product) => ({
      ...product,
      relatedProductId: product.relatedProductId || "0", // Set to "0" if it's undefined or empty
    }));
    // console.log(data);
    let myData = {
      ...data,
      id: location?.state ? location?.state?.id : 0,
      tags: data.tags.join("|"),
      relatedProducts: updatedRelatedProducts,
    };
    console.log(myData);
    try {
      if (location?.state) {
        setIsLoading(true);
        await ProductsAPI.post(myData);
        setIsLoading(false);
        toast.success("Product Updated Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      } else {
        setIsLoading(true);
        await ProductsAPI.post(myData);
        setIsLoading(false);
        toast.success("Product Updated Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      }
      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something Went Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      // let tags = location.state?.tags.split("|");
      // reset({ ...location.state, tags });
      reset(location.state);
    }
  }, [location.state, reset]);

  // if (isLoading) {
  //   return (
  //     <div
  //       className="d-flex justify-content-center align-items-center"
  //       style={{
  //         position: "fixed",
  //         top: 0,
  //         left: 0,
  //         width: "100vw",
  //         height: "100vh",
  //         backgroundColor: "rgba(0, 0, 0, 0.5)", // Backdrop effect
  //         zIndex: 9999, // Ensure it's above other content
  //       }}
  //     >
  //       <Spinner variant="primary" animation="border" />
  //     </div>
  //   );
  // }

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Card.Header className="border-bottom">
              <Row className="flex-between-center shadow-none ">
                <Col md>
                  <h5 className="fs-9 text-nowrap mb-2 mb-md-0">Product</h5>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="link"
                    className="text-secondary fw-medium p-0 me-3"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button disabled={isLoading} variant="primary" type="submit">
                    {/* {isLoading ? "Submitting.." : "Ok"} */}
                    Ok
                  </Button>
                </Col>
              </Row>
            </Card.Header>

            <div className="mb-3 py-2">
              {/* <Card.Header>
                  <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">
                    Inventory
                  </h5>
                </Card.Header> */}
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="basicInfo" title={t("general")} className="p-3 ">
                  <ProductGeneralInfo />
                </Tab>
                <Tab eventKey="mediaInfo" title={t("gallery")} className="p-3 ">
                  <MediaInfo />
                </Tab>
                <Tab
                  eventKey="specification"
                  title={t("specification")}
                  className="p-3 "
                >
                  <Specifications />
                </Tab>
                <Tab eventKey="variants" title={t("variants")} className="p-3 ">
                  <Variants />
                </Tab>
                <Tab
                  eventKey="pricingAndStock"
                  title={t("pricingAndStock")}
                  className="p-3 "
                >
                  <PricingAndStockInfo />
                </Tab>
                <Tab eventKey="detail" title={t("details")} className="p-3 ">
                  <Details />
                </Tab>
                <Tab
                  eventKey="shippingAndWarranty"
                  title={t("shippingAndWarranty")}
                  className="p-3 "
                >
                  <ShippingAndWarrantyInfo />
                </Tab>
                <Tab
                  eventKey="relatedProducts"
                  title={t("relatedProduct")}
                  className="p-3 "
                >
                  <RelatedProducts />
                </Tab>
              </Tabs>
            </div>
          </Card>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddProduct;
