// import React from "react";
// import { Form, Row, Col } from "react-bootstrap";

import { Form, Row } from "react-bootstrap";

// const DynamicStepForm = ({
//   step,
//   data,
//   register,
//   errors,
//   setValue,
//   clearErrors,
// }) => {
//   if (!data) {
//     return null;
//   }

//   const renderFormFields = () => {
//     return (
//       <div className="container">
//         <div className="row g-3">
//           {" "}
//           {/* Apply gap utilities here */}
//           {data?.map((categoryGroup, index) => (
//             <div
//               key={index}
//               className="col-md-3 mb-2" // Ensures 4 items per row
//               // onClick={() => handleItemClick(categoryGroup)}
//             >
//               <div
//                 // ${selectedItem === categoryGroup ? "border-primary" : ""}
//                 className={`border rounded cursor-pointer

//               `}
//                 style={{
//                   padding: "0",
//                   margin: "0",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   height: "100%", // Ensure height adjusts based on content
//                 }}
//               >
//                 <div
//                   style={{
//                     width: "100%",
//                     height: "150px", // Adjust the height as needed
//                     overflow: "hidden",
//                     position: "relative",
//                   }}
//                 >
//                   <img
//                     src={"https://via.placeholder.com/150"}
//                     alt={categoryGroup.name}
//                     style={{
//                       width: "100%",
//                       height: "100%",
//                       objectFit: "cover",
//                       display: "block",
//                     }}
//                   />
//                 </div>
//                 <div
//                   className="text-center"
//                   style={{ padding: "0.5rem", width: "100%" }}
//                 >
//                   {categoryGroup.name}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     );
//   };

//   return (
//     <Form noValidate>
//       <Row>{renderFormFields()}</Row>
//     </Form>
//   );
// };

// export default DynamicStepForm;

const DynamicStepForm = ({
  step,
  data,
  register,
  errors,
  setValue,
  clearErrors,
  selectedItemDynamic,
  setSelectedItemDynamic,
}) => {
  if (!data) {
    return null;
  }

  console.log(data);

  const handleItemClick = (item) => {
    setSelectedItemDynamic(item); // Capture the selected item
  };

  const renderFormFields = () => {
    return (
      <div className="container">
        <div className="row g-3">
          {data?.map((categoryGroup, index) => (
            <div
              key={index}
              className="col-md-3 mb-2"
              onClick={() => handleItemClick(categoryGroup)} // Add the click handler here
            >
              <div
                className={`border rounded cursor-pointer ${
                  selectedItemDynamic === categoryGroup ? "border-primary" : ""
                }`}
                style={{
                  padding: "0",
                  margin: "0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "150px",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <img
                    src={"https://via.placeholder.com/150"}
                    alt={categoryGroup.name}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      display: "block",
                    }}
                  />
                </div>
                <div
                  className="text-center"
                  style={{ padding: "0.5rem", width: "100%" }}
                >
                  {categoryGroup.name}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Form noValidate>
      <Row>{renderFormFields()}</Row>
    </Form>
  );
};

export default DynamicStepForm;
