// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../src/assets/locales/en/translation.json";
import fr from "../src/assets/locales/fr/translation.json";

// const resources = {
//   en: {
//     translation: en,
//   },
//   fr: {
//     translation: fr,
//   },
// };

// i18n.use(initReactI18next).init({
//   resources,
//   lng: "en", // default language
//   fallbackLng: "en", // fallback language in case the selected language is not available
//   interpolation: {
//     escapeValue: false, // React already safes from xss
//   },
// });

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

// Function to get language from local storage or default to 'en'
const getStoredLanguage = () => {
  const language = localStorage.getItem("language");
  return language ? language : "en";
};

// Function to save language to local storage
const saveLanguageToLocalStorage = (lng) => {
  localStorage.setItem("language", lng);
};

i18n.use(initReactI18next).init({
  resources,
  lng: getStoredLanguage(), // Set initial language from local storage
  fallbackLng: "en", // Fallback language
  interpolation: {
    escapeValue: false, // React already safes from xss
  },
});

// Listen for language changes and save to local storage
i18n.on("languageChanged", (lng) => {
  saveLanguageToLocalStorage(lng);
});

export default i18n;
