import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import WizardInput from "./WizardInput";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFetchCategoriesGroup } from "hooks/useFetchCategoriesGroup";

const StepOneForm = ({
  register,
  errors,
  setValue,
  clearErrors,
  selectedItem,
  setSelectedItem,
}) => {
  const { data, isLoading } = useFetchCategoriesGroup();
  const categories_group_data = data?.data.data;

  // const handleItemClick = (categoryGroup) => {
  //   setSelectedItem(categoryGroup);
  //   setValue("categoryGroup", categoryGroup);
  // };

  const handleItemClick = (categoryGroup) => {
    setSelectedItem(categoryGroup);
    setValue("categoryGroup", categoryGroup); // Update the form value
    clearErrors("categoryGroup"); // Clear any errors for category
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <>
          <div className="container">
            <div className="row g-3">
              {" "}
              {/* Apply gap utilities here */}
              {categories_group_data?.map((categoryGroup, index) => (
                <div
                  key={index}
                  className="col-md-3 mb-2" // Ensures 4 items per row
                  onClick={() => handleItemClick(categoryGroup)}
                >
                  <div
                    className={`border rounded cursor-pointer ${
                      selectedItem === categoryGroup ? "border-primary" : ""
                    }`}
                    style={{
                      padding: "0",
                      margin: "0",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "100%", // Ensure height adjusts based on content
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "150px", // Adjust the height as needed
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <img
                        // src={"https://via.placeholder.com/150"}
                        src={
                          categoryGroup?.logoFile?.fileBase64
                            ? `data:image/png;base64,${categoryGroup?.logoFile?.fileBase64}`
                            : "https://via.placeholder.com/150"
                        }
                        alt={categoryGroup.name}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          display: "block",
                        }}
                      />
                    </div>
                    <div
                      className="text-center"
                      style={{ padding: "0.5rem", width: "100%" }}
                    >
                      {categoryGroup.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {errors.categoryGroup && (
            <div className="text-danger mt-2">Please select a category.</div>
          )}
        </>
      )}
    </>
    // <>
    //   <WizardInput
    //     label="Name"
    //     name="name"
    //     errors={errors}
    //     formGroupProps={{ className: "mb-3" }}
    //     formControlProps={{
    //       ...register("name"),
    //     }}
    //   />

    //   <WizardInput
    //     type="email"
    //     errors={errors}
    //     label="Email*"
    //     name="email"
    //     formGroupProps={{ className: "mb-3" }}
    //     formControlProps={{
    //       ...register("email", {
    //         required: "Email field is required",
    //         pattern: {
    //           value:
    //             /[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/i,
    //           message: "Email must be valid",
    //         },
    //       }),
    //     }}
    //   />

    //   <Row className="g-2 mb-3">
    //     <WizardInput
    //       type="password"
    //       errors={errors}
    //       label="Password*"
    //       name="password"
    //       formGroupProps={{ as: Col, sm: 6 }}
    //       formControlProps={{
    //         ...register("password", {
    //           required: "You must specify a password",
    //           minLength: {
    //             value: 2,
    //             message: "Password must have at least 2 characters",
    //           },
    //         }),
    //       }}
    //     />
    //     <WizardInput
    //       type="password"
    //       errors={errors}
    //       label="Confirm Password*"
    //       name="confirmPassword"
    //       formGroupProps={{ as: Col, sm: 6 }}
    //       formControlProps={{
    //         ...register("confirmPassword", {
    //           required: "Confirm Password field is required",
    //           validate: (value) =>
    //             value === watch("password") || "The password do not match",
    //         }),
    //       }}
    //     />
    //   </Row>

    //   <WizardInput
    //     type="checkbox"
    //     errors={errors}
    //     label={
    //       <>
    //         I accept the <Link to="#!"> terms</Link> and{" "}
    //         <Link to="#!"> privacy policy</Link>
    //       </>
    //     }
    //     name="agreedToTerms"
    //     formControlProps={{
    //       ...register("agreedToTerms", {
    //         required: "You need to agree the terms and privacy policy.",
    //       }),
    //     }}
    //   />
    // </>
  );
};

// AccountForm.propTypes = {
//   register: PropTypes.func.isRequired,
//   errors: PropTypes.object,
//   watch: PropTypes.func,
// };

export default StepOneForm;
