import { yupResolver } from "@hookform/resolvers/yup";
import { CategoryFieldSetsAPI } from "api";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import React, { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import * as yup from "yup";
import CategoryFieldSetBasicInformation from "./CategoryFieldSetBasicInformation";

const schema = yup
  .object({
    categoryId: yup.string().required("Category is required"),
    name: yup.string().required("Name is required"),
    fieldSets: yup
      .array()
      .of(
        yup.object().shape({
          fieldsetId: yup.string().required("Fieldset is required"),
          sortOrder: yup.string(),
          status: yup.boolean(),
        })
      )
      .required("Fieldset is required"),
    status: yup.boolean(),
  })
  .required();

const AddCategoryFieldSet = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fieldSets: [{}],
    },
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data) => {
    let myData = {
      ...data,
      id: location?.state?.id ?? 0, // Use nullish coalescing to handle undefined or null
    };

    console.log(myData);

    try {
      if (location?.state) {
        await CategoryFieldSetsAPI.post(myData);
        toast.success("Category FieldSet Updated Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      } else {
        await CategoryFieldSetsAPI.post(myData);
        toast.success("Category FieldSet Created Successfully", {
          position: customSettings.toastPosition,
          autoClose: customSettings.toastClose,
        });
      }

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something Went Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      // Transform each fieldset to replace `fieldsetId` with `Id`
      const transformedData = {
        ...location.state,
        fieldSets: location.state.fieldSets.map((fieldset) => ({
          ...fieldset,
          fieldsetId: fieldset.id,
        })),
      };

      // Reset the form with transformed data
      reset(transformedData);
    }
  }, [location.state, reset]);

  // useEffect(() => {
  //   if (location.state) {
  //     // console.log(location.state);
  //     reset(location.state);
  //   }
  // }, []);

  return (
    <>
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <Card.Header className="border-bottom">
              <Row className="flex-between-center ">
                <Col md>
                  <h5 className="fs-9 text-nowrap mb-2 mb-md-0">
                    Category FieldSet
                  </h5>
                </Col>
                <Col xs="auto" style={{ paddingRight: "15px" }}>
                  <Button
                    variant="link"
                    className="text-secondary fw-medium p-0 me-3"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Ok
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <CategoryFieldSetBasicInformation />
            {/* <Row className="">
            
              <Col lg={12}>
              </Col> */}
            {/* <Col xs={12}>
    
            </Col> */}
            {/* </Row> */}
          </Card>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddCategoryFieldSet;
