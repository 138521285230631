import axios from "axios";
import FalconLightBox from "components/common/FalconLightBox";
import Flex from "components/common/Flex";
import QuillEditor from "components/common/QuillEditor";
import SubtleBadge from "components/common/SubtleBadge";
import { useFetchCategoriesGroup } from "hooks/useFetchCategoriesGroup";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { FaImage } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";

const PostBasicInformation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const {
    register,
    setValue,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [tags, setTags] = useState(getValues("tags") || []);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const titleWatch = watch("title");
  const authorWatch = watch("author");
  const linkWatch = watch("link");
  const maxCharactersLength = 255;

  const { data: categoriesGroupData, isLoading: categoriesGroupLoading } =
    useFetchCategoriesGroup();

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      "image/*": [".jpeg", ".png", ".jpg"],
    },
    onDrop: async (acceptedFiles) => {
      const uploadedFile = acceptedFiles[0];
      const uploadedFileUrl = await uploadFile(uploadedFile);

      setValue("backgroundImage", uploadedFileUrl);

      setFile(
        Object.assign(uploadedFile, {
          preview: URL.createObjectURL(uploadedFile),
        })
      );
    },
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue) {
      event.preventDefault();
      if (!tags.includes(inputValue.trim())) {
        setTags([...tags, inputValue.trim()]);
      }
      setInputValue("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  useEffect(() => {
    // Register fields with react-hook-form
    register("contentDetail");
  }, [register]);

  useEffect(() => {
    if (state) {
      console.log(state);
      setTags(state?.tagsList);
      setValue("contentDetail", state.contentDetail || "");
    }
  }, [state, setValue]);

  useEffect(() => {
    // Update form value for tags when local state changes
    setValue("tags", tags);
    let date = state?.postDate
      ? new Date(state?.postDate).toISOString().split("T")[0]
      : "";
    setValue("postDate", date);
  }, [tags, setValue]);
  // const handleKeyPress = (index, e) => {
  //   if (e.key === "Enter" && inputValues[index]) {
  //     e.preventDefault();
  //     const trimmedValue = inputValues[index].trim();
  //     if (!optionsList[index]?.includes(trimmedValue)) {
  //       const updatedOptions = [...(optionsList[index] || []), trimmedValue];
  //       setOptionsList((prevOptions) => ({
  //         ...prevOptions,
  //         [index]: updatedOptions,
  //       }));
  //       setValue(`options[${index}].options`, updatedOptions);
  //     }
  //     setInputValues((prevInputValues) => ({
  //       ...prevInputValues,
  //       [index]: "",
  //     }));
  //   }
  // };

  // const handleRemoveTag = (index, tagToRemove) => {
  //   const updatedOptions = optionsList[index]?.filter(
  //     (tag) => tag !== tagToRemove
  //   );
  //   setOptionsList((prevOptions) => ({
  //     ...prevOptions,
  //     [index]: updatedOptions,
  //   }));
  //   setValue(`options[${index}].options`, updatedOptions);
  // };

  // const handleInputChange = (index, value) => {
  //   setInputValues((prevInputValues) => ({
  //     ...prevInputValues,
  //     [index]: value,
  //   }));
  // };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      const response = await axios.post("/Catalogue/CategoryFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);

      // Assuming the response contains the URL of the uploaded file
      return response.data[0].destination;
    } catch (error) {
      console.error("File upload failed:", error);
      toast.error("Failed to File Upload", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      return "";
    }
  };
  return (
    <>
      {categoriesGroupLoading ? (
        <div className="d-flex py-3 justify-content-center align-items-center">
          <Button variant="falcon-default" size="sm" className="fs-10">
            Loading...
          </Button>
        </div>
      ) : (
        <Card.Body className="" style={{ padding: "5px 20px" }}>
          <Row className="gx-2 pb-2">
            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="text"
                  placeholder="Title"
                  title="Title"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.title}
                  {...register("title")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.title?.message}
                  </Form.Control.Feedback>
                  <small className="fs-10 text-muted">
                    {`${titleWatch?.length || 0} / ${maxCharactersLength}`}
                  </small>
                </div>
                {/* <Form.Control.Feedback type="invalid">
                  {errors.title?.message}
                </Form.Control.Feedback> */}
                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Select
                  aria-label="CategoryGroup"
                  type="text"
                  className="mb-1 pb-2"
                  title="Category Group"
                  isInvalid={!!errors?.categoryGroupId}
                  {...register("categoryGroupId")}
                >
                  <option value="">Select Category Group</option>
                  {categoriesGroupData?.data?.data.map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors?.categoryGroupId?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="text"
                  placeholder="Author"
                  title="Author"
                  maxLength={maxCharactersLength}
                  className="py-2 "
                  isInvalid={!!errors.author}
                  {...register("author")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.author?.message}
                  </Form.Control.Feedback>
                  <small className="fs-10 text-muted">
                    {`${authorWatch?.length || 0} / ${maxCharactersLength}`}
                  </small>
                </div>
                {/* <Form.Control.Feedback type="invalid">
                  {errors.author?.message}
                </Form.Control.Feedback> */}
                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="number"
                  placeholder="Display Order"
                  title="Display Order"
                  className="py-2 mb-1"
                  isInvalid={!!errors.sortOrder}
                  {...register("sortOrder")}
                  onChange={(e) => {
                    // clearErrors("sortOrder");
                    const value = e.target.value;
                    // Allow only positive integers or empty
                    if (value === "" || /^[0-9]\d*$/.test(value)) {
                      e.target.value = value; // Set the value if it's valid
                    } else {
                      e.target.value = ""; // Reset if invalid
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.sortOrder?.message}
                </Form.Control.Feedback>
                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="text"
                  placeholder="Link"
                  title="Link"
                  maxLength={maxCharactersLength}
                  className="py-2"
                  isInvalid={!!errors.link}
                  {...register("link")}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-inline w-75"
                  >
                    {errors.link?.message}
                  </Form.Control.Feedback>
                  <small className="fs-10 text-muted">
                    {`${linkWatch?.length || 0} / ${maxCharactersLength}`}
                  </small>
                </div>

                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                {/* <FloatingLabel controlId="floatingInput" label="Name"> */}
                <Form.Control
                  type="date"
                  placeholder="Date"
                  title="Date"
                  className="py-2 mb-1"
                  isInvalid={!!errors.postDate}
                  // defaultValue={state?.postDate.split("T")[0]}
                  {...register("postDate")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.postDate?.message}
                </Form.Control.Feedback>
                {/* </FloatingLabel> */}
              </Form.Group>
            </Col>
            <Col md="6">
              <div className="form-control d-flex flex-wrap align-items-center mb-1">
                {tags.map((tag, index) => (
                  <SubtleBadge key={index} className=" mr-2 mb-1 mx-1">
                    {tag}
                    <span
                      className="ml-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRemoveTag(tag)}
                    >
                      &times;
                    </span>
                  </SubtleBadge>
                ))}
                <input
                  type="text"
                  title="Tags"
                  className="border-0 flex-grow-1"
                  style={{
                    outline: "none",
                    color: "black",
                    minWidth: "120px",
                    // paddingBottom: "10px",
                    // paddingTop: "10px",
                  }}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Tags"
                />
              </div>
            </Col>
            <Col md="6">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <div {...getRootProps()}>
                    <input {...getInputProps({ multiple: false })} />
                    <Flex justifyContent="start" alignItems="center">
                      <FaImage size={27} title="Image" className="mt-2 cursor-pointer mx-1" />
                      {/* <p className="fs-9 mb-0 text-700"> Select Logo</p> */}
                    </Flex>
                  </div>

                  {/* <Col md={file || (state?.logoFile?.fileBase64 ? "2" : "1")}> */}
                  {file || state?.logoFile?.fileBase64 ? (
                    <FalconLightBox
                      image={
                        file
                          ? file.preview
                          : `data:image/${state.logoFile.fileBase64
                            .split(".")
                            .pop()};base64,${state.logoFile.fileBase64}`
                      }
                    >
                      <Image
                        src={
                          file
                            ? file.preview
                            : `data:image/${state.logoFile.fileBase64
                              .split(".")
                              .pop()};base64,${state.logoFile.fileBase64}`
                        }
                        rounded
                        width={80}
                        height={40}
                      />
                    </FalconLightBox>
                  ) : null}
                  {/* Render nothing if both file and state.logoFile.fileBase64 are not present */}
                </div>
                <div className="d-flex gap-2">
                  <Form.Check
                    type="checkbox"
                    id="defaultSwitch"
                    label="Is Published"
                    className="mt-2"
                    defaultChecked={!state}
                    isInvalid={!!errors.isPublished}
                    {...register("isPublished")}
                  />
                  <Form.Check
                    type="checkbox"
                    id="defaultSwitch"
                    label="Active"
                    className="mt-2"
                    defaultChecked={!state}
                    isInvalid={!!errors.status}
                    {...register("status")}
                  />
                </div>
              </div>
            </Col>

            <Col md={12}>
              <QuillEditor
                placeholder="Content"
                setValue={(value) => setValue("contentDetail", value)} // Sync with react-hook-form
                value={watch("contentDetail")} // Get the value from react-hook-form
              />
            </Col>
          </Row>

        </Card.Body>
      )}
    </>
  );
};

export default PostBasicInformation;
