import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

/**
 * Custom hook to prompt a confirmation dialog before navigating away from the page.
 * @param {boolean} isDirty - Indicates if the form has unsaved changes or not.
 */
function useNavigationPrompt(isDirty) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = ""; // Required for Chrome to show the dialog
      }
    };

    const handleBeforeNavigate = (event) => {
      if (isDirty) {
        const shouldNavigate = window.confirm(
          "You have unsaved changes, are you sure you want to leave?"
        );
        if (!shouldNavigate) {
          event.preventDefault();
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.history.pushState(null, null, location.pathname);
    window.addEventListener("popstate", handleBeforeNavigate);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleBeforeNavigate);
    };
  }, [isDirty, location.pathname]);
}

export default useNavigationPrompt;
