import QuillEditor from "components/common/QuillEditor";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Details = () => {
  const {
    register,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { state } = useLocation();
  const navigate = useNavigate();

  // const [description, setDescription] = useState("");
  // const [highlights, setHighlights] = useState("");
  // const [insideBox, setInsideBox] = useState("");

  // console.log(state);

  // useEffect(() => {
  //   // Register fields with react-hook-form
  //   register("description");
  //   register("highlights");
  //   register("insideBox");
  // }, [register]);

  // useEffect(() => {
  //   if (state) {
  //     setValue("description", state.description || "");
  //     setValue("highlights", state.highlights || "");
  //     setValue("insideBox", state.insideBox || "");
  //   }
  // }, [state, setValue]);

  // // Update form values when local state changes
  // useEffect(() => {
  //   setValue("description", description);
  //   setValue("highlights", highlights);
  //   setValue("insideBox", insideBox);
  // }, [description, highlights, insideBox, setValue]);

  // console.log(description);

  useEffect(() => {
    // Register fields with react-hook-form
    register("description");
    register("highlights");
    register("insideBox");
  }, [register]);

  useEffect(() => {
    if (state) {
      setValue("description", state.description || "");
      setValue("highlights", state.highlights || "");
      setValue("insideBox", state.insideBox || "");
    }
  }, [state, setValue]);

  return (
    <>
      {/* <Card.Header as="h6" className="bg-body-tertiary mb-2">
        Details
      </Card.Header> */}
      {/* <Row className="gx-1 gy-1">
        <Col md={12}>
          <Form.Label>Description</Form.Label>
          <QuillEditor
            setValue={(value) => {
              setDescription(value); // Update local state
              setValue("description", value); // Sync with react-hook-form
            }}
            value={watch("description")} // Get the value from react-hook-form
          />
        </Col>
        <Col md={12}>
          <Form.Label>Highlights</Form.Label>
          <QuillEditor
            setValue={(value) => {
              setHighlights(value); // Update local state
              setValue("highlights", value); // Sync with react-hook-form
            }}
            value={watch("highlights")} // Get the value from react-hook-form
          />
        </Col>
        <Col md={12}>
          <Form.Label>What's Inside the Box?</Form.Label>
          <QuillEditor
            setValue={(value) => {
              setInsideBox(value); // Update local state
              setValue("insideBox", value); // Sync with react-hook-form
            }}
            value={watch("insideBox")} // Get the value from react-hook-form
          />
        </Col>
      </Row> */}

      <Row className="gx-1 gy-1">
        <Col md={12}>
          <Form.Label>Description</Form.Label>
          <QuillEditor
            setValue={(value) => setValue("description", value)} // Sync with react-hook-form
            value={watch("description")} // Get the value from react-hook-form
          />
        </Col>
        <Col md={12}>
          <Form.Label>Highlights</Form.Label>
          <QuillEditor
            setValue={(value) => setValue("highlights", value)} // Sync with react-hook-form
            value={watch("highlights")} // Get the value from react-hook-form
          />
        </Col>
        <Col md={12}>
          <Form.Label>What's Inside the Box?</Form.Label>
          <QuillEditor
            setValue={(value) => setValue("insideBox", value)} // Sync with react-hook-form
            value={watch("insideBox")} // Get the value from react-hook-form
          />
        </Col>
      </Row>
    </>
  );
};

export default Details;
