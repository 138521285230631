import React from "react";
import PropTypes from "prop-types";
import WizardLayout from "./WizardLayout";
import AuthWizardProvider from "./AuthWizardProvider";

const GetAnEstimate = ({ variant, validation, progressBar }) => {
  return (
    <AuthWizardProvider>
      <WizardLayout
        variant={variant}
        validation={validation}
        progressBar={progressBar}
      />
    </AuthWizardProvider>
  );
};

export default GetAnEstimate;
