export const dashboardRoutes = {
  label: "Dashboard",
  labelDisable: true,

  children: [
    {
      name: "Dashboard",
      to: "/",
      exact: true,
      active: true,
      icon: "chart-pie",

      // name: "Dashboard",
      // active: true,
      // icon: "chart-pie",
      // children: [
      //   {
      //     name: "Default",
      //     to: "/",
      //     exact: true,
      //     active: true,
      //   },
      // {
      //   name: "Analytics",
      //   to: "/dashboard/analytics",
      //   active: true,
      // },
      // {
      //   name: "CRM",
      //   to: "/dashboard/crm",
      //   active: true,
      // },
      // {
      //   name: "E Commerce",
      //   to: "/dashboard/e-commerce",
      //   active: true,
      // },
      // {
      //   name: "LMS",
      //   to: "/dashboard/lms",
      //   active: true,
      //   badge: {
      //     type: "success",
      //     text: "New",
      //   },
      // },
      // {
      //   name: "Management",
      //   to: "/dashboard/project-management",
      //   active: true,
      // },
      // {
      //   name: "SaaS",
      //   to: "/dashboard/saas",
      //   active: true,
      // },
      // {
      //   name: "Support desk",
      //   to: "/dashboard/support-desk",
      //   active: true,
      //   badge: {
      //     type: "success",
      //     text: "New",
      //   },
      // },
      // ],
    },
  ],
};
export const appRoutes = {
  label: "app",
  children: [
    {
      name: "Libraries",
      active: true,
      icon: "table",
      children: [
        {
          name: "Categories",
          to: "/categories",
          exact: true,
          active: true,
        },
        {
          name: "Classes",
          to: "/classes",
          exact: true,
          active: true,
        },
        {
          name: "Channels",
          to: "/channels",
          exact: true,
          active: true,
        },
        {
          name: "Sizes",
          to: "/sizes",
          exact: true,
          active: true,
        },
        {
          name: "Unit of Measurements",
          to: "/unit-of-measurement",
          exact: true,
          active: true,
        },
        {
          name: "Places",
          to: "/places",
          exact: true,
          active: true,
        },
        {
          name: "Regions",
          to: "/regions",
          exact: true,
          active: true,
        },
        {
          name: "Cities",
          to: "/cities",
          exact: true,
          active: true,
        },
        {
          name: "Locations",
          to: "/locations",
          exact: true,
          active: true,
        },
        {
          name: "Location Addresses",
          to: "/location-addresses",
          exact: true,
          active: true,
        },
        {
          name: "Schools",
          to: "/schools",
          exact: true,
          active: true,
        },
        {
          name: "HoReCa",
          to: "/horeca",
          exact: true,
          active: true,
        },
        {
          name: "Authorities Rate List",
          to: "/authorities-rate-list",
          exact: true,
          active: true,
        },
        {
          name: "Contacts",
          to: "/contacts",
          exact: true,
          active: true,
        },
        {
          name: "Authorities",
          to: "/authorities",
          exact: true,
          active: true,
        },
        {
          name: "Vendors",
          to: "/vendors",
          exact: true,
          active: true,
        },
        {
          name: "Agencies",
          to: "/agencies",
          exact: true,
          active: true,
        },
        {
          name: "Clients",
          to: "/clients",
          exact: true,
          active: true,
        },
        {
          name: "Partners",
          to: "/partners",
          exact: true,
          active: true,
        },
        {
          name: "Business Units",
          to: "/business-units",
          exact: true,
          active: true,
        },
        {
          name: "Brand Groups",
          to: "/brand-groups",
          exact: true,
          active: true,
        },
        {
          name: "Brans",
          to: "/brans",
          exact: true,
          active: true,
        },
        {
          name: "Store",
          to: "/stores",
          exact: true,
          active: true,
        },
        {
          name: "Sites",
          to: "/sites",
          exact: true,
          active: true,
        },
        {
          name: "Venues",
          to: "/venues",
          exact: true,
          active: true,
        },
        {
          name: "Mediums",
          to: "/mediums",
          exact: true,
          active: true,
        },
        {
          name: "Drivers",
          to: "/drivers",
          exact: true,
          active: true,
        },
        {
          name: "Vehicles",
          to: "/vehicles",
          exact: true,
          active: true,
        },
      ],
    },
    {
      name: "Inventory",
      active: true,
      icon: "shopping-cart",
      children: [
        {
          name: "Products",
          to: "/products",
          exact: true,
          active: true,
        },
      ],
    },
    {
      name: "Human Resource",
      active: true,
      icon: "users",
      children: [
        {
          name: "Promoters / HR",
          to: "/promoters",
          exact: true,
          active: true,
        },
        {
          name: "Attendances",
          to: "/attendances",
          exact: true,
          active: true,
        },
      ],
    },
    {
      name: "Administration",
      active: true,
      icon: "user",
      children: [
        {
          name: "Groups",
          to: "/groups",
          exact: true,
          active: true,
        },
        {
          name: "Roles & Permission",
          to: "/roles-and-permissions",
          exact: true,
          active: true,
        },
        {
          name: "Users",
          to: "/users",
          exact: true,
          active: true,
        },
      ],
    },
    // {
    //   name: "Users",
    //   to: "/users",
    //   active: true,
    //   icon: "users",
    // },
    // {
    //   name: "Roles & Permissions",
    //   to: "/roles",
    //   active: true,
    //   icon: "book",
    // },
    // {
    //   name: "Infinite Scroll",
    //   to: "/infinite_scroll",
    //   active: true,
    //   icon: "book",
    // },
    // {
    //   name: "Product Tabs",
    //   to: "/products-tabs",
    //   icon: "shopping-cart",
    //   active: true,
    // },
    // {
    //   name: "Wizard",
    //   to: "/wizard",
    //   icon: "list",
    //   active: true,
    // },
    // {
    //   name: "Data Grid One",
    //   to: "/data-grid",
    //   icon: "list",
    //   active: true,
    // },
    // {
    //   name: "Data Grid ",
    //   to: "/ag-grid",
    //   icon: "list",
    //   active: true,
    // },
    // {
    //   name: "Tan Stack",
    //   to: "/tan-stack-table",
    //   icon: "list",
    //   active: true,
    // },
    // {
    //   name: "Wizard",
    //   to: "/wizard",
    //   icon: "list",
    //   active: true,
    // },
    // {
    //   name: "Modal Page",
    //   to: "/modal-page",
    //   icon: "list",
    //   active: true,
    // },
    // {
    //   name: "Lock Screen",
    //   to: "/authentication/lock-screen",
    //   icon: "list",
    //   active: true,
    // },
    // {
    //   name: "Logout",
    //   to: "/authentication/logout",
    //   icon: "list",
    //   active: true,
    // },
    // {
    //   name: "Forget Password",
    //   to: "/authentication/forgot-password",
    //   icon: "list",
    //   active: true,
    // },
    // {
    //   name: "Confirm Email",
    //   to: "/authentication/confirm-mail",
    //   icon: "list",
    //   active: true,
    // },
    // {
    //   name: "Reset Password",
    //   to: "/authentication/reset-password",
    //   icon: "list",
    //   active: true,
    // },

    // {
    //   name: "Calendar",
    //   icon: "calendar-alt",
    //   to: "/app/calendar",
    //   active: true,
    // },
    // {
    //   name: "Chat",
    //   icon: "comments",
    //   to: "/app/chat",
    //   active: true,
    // },
    // {
    //   name: "Email",
    //   icon: "envelope-open",
    //   active: true,
    //   children: [
    //     {
    //       name: "Inbox",
    //       to: "/email/inbox",
    //       active: true,
    //     },
    //     {
    //       name: "Email detail",
    //       to: "/email/email-detail",
    //       active: true,
    //     },
    //     {
    //       name: "Compose",
    //       to: "/email/compose",
    //       active: true,
    //     },
    //   ],
    // },
    // {
    //   name: "Events",
    //   icon: "calendar-day",
    //   active: true,
    //   children: [
    //     {
    //       name: "Create an event",
    //       to: "/events/create-an-event",
    //       active: true,
    //     },
    //     {
    //       name: "Event detail",
    //       to: "/events/event-detail",
    //       active: true,
    //     },
    //     {
    //       name: "Event list",
    //       to: "/events/event-list",
    //       active: true,
    //     },
    //   ],
    // },
    // {
    //   name: "E Commerce",
    //   icon: "shopping-cart",
    //   active: true,
    //   children: [
    //     {
    //       name: "Product",
    //       active: true,
    //       children: [
    //         {
    //           name: "Add a product",
    //           to: "/e-commerce/product/add-product",
    //           active: true,
    //         },
    //         {
    //           name: "Product list",
    //           to: "/e-commerce/product/product-list",
    //           active: true,
    //         },
    //         {
    //           name: "Product grid",
    //           to: "/e-commerce/product/product-grid",
    //           active: true,
    //         },
    //         {
    //           name: "Product details",
    //           to: "/e-commerce/product/product-details",
    //           active: true,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Orders",
    //       active: true,
    //       children: [
    //         {
    //           name: "Order list",
    //           to: "/e-commerce/orders/order-list",
    //           active: true,
    //         },
    //         {
    //           name: "Order details",
    //           to: "/e-commerce/orders/order-details",
    //           active: true,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Customers",
    //       to: "/e-commerce/customers",
    //       active: true,
    //     },
    //     {
    //       name: "Customer details",
    //       to: "/e-commerce/customer-details",
    //       active: true,
    //     },
    //     {
    //       name: "Shopping cart",
    //       to: "/e-commerce/shopping-cart",
    //       active: true,
    //     },
    //     {
    //       name: "Checkout",
    //       to: "/e-commerce/checkout",
    //       active: true,
    //     },
    //     {
    //       name: "Billing",
    //       to: "/e-commerce/billing",
    //       active: true,
    //     },
    //     {
    //       name: "Invoice",
    //       to: "/e-commerce/invoice",
    //       active: true,
    //     },
    //   ],
    // },
    // {
    //   name: "E Learning",
    //   icon: "graduation-cap",
    //   active: true,
    //   badge: {
    //     type: "success",
    //     text: "New",
    //   },
    //   children: [
    //     {
    //       name: "Course",
    //       active: true,
    //       children: [
    //         {
    //           name: "Create a course",
    //           to: "/e-learning/course/create-a-course",
    //           active: true,
    //         },
    //         {
    //           name: "Course list",
    //           to: "/e-learning/course/course-list",
    //           active: true,
    //         },
    //         {
    //           name: "Course grid",
    //           to: "/e-learning/course/course-grid",
    //           active: true,
    //         },
    //         {
    //           name: "Course details",
    //           to: "/e-learning/course/course-details",
    //           active: true,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Student overview",
    //       to: "/e-learning/student-overview",
    //       active: true,
    //     },
    //     {
    //       name: "Trainer profile",
    //       to: "/e-learning/trainer-profile",
    //       active: true,
    //     },
    //   ],
    // },
    // {
    //   name: "Kanban",
    //   icon: ["fab", "trello"],
    //   to: "/app/kanban",
    //   active: true,
    // },
    // {
    //   name: "Social",
    //   icon: "share-alt",
    //   active: true,
    //   children: [
    //     {
    //       name: "Feed",
    //       to: "/social/feed",
    //       active: true,
    //     },
    //     {
    //       name: "Activity log",
    //       to: "/social/activity-log",
    //       active: true,
    //     },
    //     {
    //       name: "Notifications",
    //       to: "/social/notifications",
    //       active: true,
    //     },
    //     {
    //       name: "Followers",
    //       to: "/social/followers",
    //       active: true,
    //     },
    //   ],
    // },
    // {
    //   name: "Support desk",
    //   icon: "ticket-alt",
    //   active: true,
    //   children: [
    //     {
    //       name: "Table view",
    //       to: "/support-desk/table-view",
    //       active: true,
    //     },
    //     {
    //       name: "Card view",
    //       to: "/support-desk/card-view",
    //       active: true,
    //     },
    //     {
    //       name: "Contacts",
    //       to: "/support-desk/contacts",
    //       active: true,
    //     },
    //     {
    //       name: "Contact details",
    //       to: "/support-desk/contact-details",
    //       active: true,
    //     },
    //     {
    //       name: "Tickets preview",
    //       to: "/support-desk/tickets-preview",
    //       active: true,
    //     },
    //     {
    //       name: "Quick links",
    //       to: "/support-desk/quick-links",
    //       active: true,
    //     },
    //     {
    //       name: "Reports",
    //       to: "/support-desk/reports",
    //       active: true,
    //     },
    //   ],
    // },
  ],
};

export default [
  dashboardRoutes,
  appRoutes,
  // pagesRoutes,
  // modulesRoutes,
  // documentationRoutes,
];
