import { useQuery } from "@tanstack/react-query";
import { CategoryFieldSetsAPI } from "api";

const fetchCategoryFieldSet = () => {
  return CategoryFieldSetsAPI.get();
};
export const useFetchCategoryFieldSets = () => {
  return useQuery({
    queryKey: ["categoryFieldSet"],
    queryFn: fetchCategoryFieldSet,
  });
};
