// import { useFetchCategoryFieldSets } from "hooks/useCategoryFieldSet";
// import { useFetchDetailsFieldSets } from "hooks/useFetchDetailFieldSets";
// import useQuery from "hooks/useQuery";
// import React, { useEffect, useMemo, useState } from "react";
// import {
//   Button,
//   Card,
//   Col,
//   FloatingLabel,
//   Form,
//   Row,
//   Spinner,
// } from "react-bootstrap";
// import { useFormContext } from "react-hook-form";
// import { useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import Select from "react-select";
// import { toast } from "react-toastify";

// const Specifications = () => {
//   const {
//     register,
//     getValues,
//     watch,
//     setValue,
//     formState: { errors },
//   } = useFormContext();
//   const { pathname } = useLocation();
//   const navigate = useNavigate();

//   const categoryId = watch("categoryId");
//   const [inputs, setInputs] = useState([]);

//   const { data: fieldSetData, isLoading: fieldSetLoading } =
//     useFetchDetailsFieldSets();

//   const { data: categoryFieldSetData, isLoading: categoryFieldSetLoading } =
//     useFetchCategoryFieldSets();

//   const filteredCategoryFieldSets = useMemo(() => {
//     if (!categoryFieldSetData || !categoryId) return [];
//     return categoryFieldSetData?.data?.data?.filter(
//       (set) => set.categoryId === categoryId
//     );
//   }, [categoryFieldSetData, categoryId]);

//   const fieldSetIds = useMemo(() => {
//     return filteredCategoryFieldSets?.flatMap((set) =>
//       set.fieldSets.map((fs) => fs.fieldsetId)
//     );
//   }, [filteredCategoryFieldSets]);

//   const filteredFields = useMemo(() => {
//     if (!fieldSetData || fieldSetIds.length === 0) return [];
//     return fieldSetData?.data?.data?.filter((field) =>
//       fieldSetIds.includes(field.id)
//     );
//   }, [fieldSetData, fieldSetIds]);

//   console.log(filteredFields);

//   // useEffect(() => {
//   //   const initialInputs = filteredFields.flatMap((f) =>
//   //     f.fields.map((innerF) => ({
//   //       fieldSetId: f.id,
//   //       fieldId: innerF.id,
//   //       fieldValue: "",
//   //       status: false,
//   //     }))
//   //   );

//   //   setInputs(initialInputs);
//   //   setValue("specifications", initialInputs);
//   // }, [filteredFields]);

//   // console.log(inputs);

//   // const handleInputChange = (index, field, value) => {
//   //   const updatedInputs = inputs.map((item, i) =>
//   //     i === index ? { ...item, [field]: value } : item
//   //   );

//   //   setInputs(updatedInputs);
//   //   setValue("specifications", updatedInputs);
//   // };

//   useEffect(() => {
//     const initialInputs = filteredFields.flatMap((f) =>
//       f.fields.map((innerF) => ({
//         fieldSetId: f.id,
//         fieldId: innerF.id,
//         fieldValue: "",
//         status: false,
//       }))
//     );

//     setInputs(initialInputs);
//     setValue("specifications", initialInputs);
//   }, [filteredFields, setValue]);

//   const handleInputChange = (fieldSetId, fieldId, field, value) => {
//     // const updatedInputs = inputs.map((item) =>
//     //   item.fieldSetId === fieldSetId && item.fieldId === fieldId
//     //     ? { ...item, [field]: value }
//     //     : item
//     // );

//     // setInputs(updatedInputs);
//     // setValue("specifications", updatedInputs);

//     const updatedInputs = inputs.map((item) =>
//       item.fieldSetId === fieldSetId && item.fieldId === fieldId
//         ? {
//             ...item,
//             fieldValue: selectedOptions
//               ? selectedOptions.map((option) => option.value).join("|") // Join selected options with "|"
//               : "",
//           }
//         : item
//     );

//     setInputs(updatedInputs);
//     setValue("specifications", updatedInputs);
//   };

//   if (fieldSetLoading || categoryFieldSetLoading) {
//     return <Spinner variant="primary" animation="border" />;
//   }

//   return (
//     <>
//       {/* <Card.Header as="h6" className="bg-body-tertiary mb-2">
//         Specifications
//       </Card.Header> */}

//       <Row className="gx-2">
//         {filteredFields.map((fieldSet, setIndex) => (
//           <Row key={setIndex}>
//             {fieldSet.fields.length > 0 && (
//               <Card.Header as="h6" className="bg-body-tertiary text-800 mb-2">
//                 {fieldSet.name}
//               </Card.Header>
//             )}

//             {fieldSet.fields.map((field, fieldIndex) => {
//               const currentInput = inputs.find(
//                 (input) =>
//                   input.fieldSetId === fieldSet.id && input.fieldId === field.id
//               );
//               const value = currentInput?.fieldValue || "";

//               const selectedValues = currentInput?.fieldValue
//                 ? currentInput.fieldValue
//                     .split("|")
//                     .map((value) => ({ value, label: value }))
//                 : [];

//               return (
//                 <Col md="4" key={field.id}>
//                   <Form.Group className="mb-3" controlId={field.id}>
//                     <Form.Label className="fs-9">{field.label}</Form.Label>
//                     {(field.type === "text" ||
//                       field.type === "password" ||
//                       field.type === "date" ||
//                       field.type === "color" ||
//                       field.type === "currency" ||
//                       field.type === "email" ||
//                       field.type === "number" ||
//                       field.type === "time" ||
//                       field.type === "datetime-local") && (
//                       <Form.Control
//                         type={field.type}
//                         placeholder={field.placeholder}
//                         value={value}
//                         onChange={(e) =>
//                           handleInputChange(
//                             fieldSet.id,
//                             field.id,
//                             "fieldValue",
//                             e.target.value
//                           )
//                         }
//                       />
//                     )}
//                     {field.type === "select" && (
//                       <Form.Select
//                         value={value}
//                         onChange={(e) =>
//                           handleInputChange(
//                             fieldSet.id,
//                             field.id,
//                             "fieldValue",
//                             e.target.value
//                           )
//                         }
//                       >
//                         <option value="">Select</option>
//                         {field.listValues.split("|").map((option, index) => (
//                           <option key={index} value={option}>
//                             {option}
//                           </option>
//                         ))}
//                       </Form.Select>
//                     )}
//                     {field.type === "multiSelect" && (
//                       <Select
//                         closeMenuOnSelect={false}
//                         options={field.listValues
//                           .split("|")
//                           .map((value) => ({ value, label: value }))}
//                         placeholder="Select..."
//                         isMulti
//                         classNamePrefix="react-select"
//                         value={selectedValues}
//                         onChange={(selectedOptions) =>
//                           handleInputChange(
//                             fieldSet.id,
//                             field.id,
//                             selectedOptions || [] // Ensure selectedOptions is an array
//                           )
//                         }
//                       />
//                     )}
//                     {field.type === "checkbox" && (
//                       <Form.Check
//                         type="checkbox"
//                         label={field.label}
//                         checked={value === "true"}
//                         onChange={(e) =>
//                           handleInputChange(
//                             fieldSet.id,
//                             field.id,
//                             "fieldValue",
//                             e.target.checked ? "true" : "false"
//                           )
//                         }
//                       />
//                     )}
//                     {field.type === "radio" && (
//                       <div>
//                         {field.listValues.split("|").map((option, index) => (
//                           <Form.Check
//                             type="radio"
//                             name={field.id}
//                             key={index}
//                             value={option}
//                             label={option}
//                             checked={value === option}
//                             onChange={(e) =>
//                               handleInputChange(
//                                 fieldSet.id,
//                                 field.id,
//                                 "fieldValue",
//                                 e.target.value
//                               )
//                             }
//                           />
//                         ))}
//                       </div>
//                     )}
//                     {field.type === "textarea" && (
//                       <Form.Control
//                         as="textarea"
//                         placeholder={field.placeholder}
//                         value={value}
//                         onChange={(e) =>
//                           handleInputChange(
//                             fieldSet.id,
//                             field.id,
//                             "fieldValue",
//                             e.target.value
//                           )
//                         }
//                       />
//                     )}
//                   </Form.Group>
//                 </Col>
//               );
//             })}
//           </Row>
//         ))}
//       </Row>
//     </>
//   );
// };

// export default Specifications;

// import { useFetchCategoryFieldSets } from "hooks/useCategoryFieldSet";
// import { useFetchDetailsFieldSets } from "hooks/useFetchDetailFieldSets";
// import React, { useEffect, useMemo, useState } from "react";
// import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
// import { useFormContext } from "react-hook-form";
// import { useLocation } from "react-router-dom";
// import Select from "react-select";

// const Specifications = () => {
//   const {
//     setValue,
//     watch,
//     formState: { errors },
//   } = useFormContext();
//   const categoryId = watch("categoryId");
//   const { state } = useLocation();
//   const [inputs, setInputs] = useState([]);

//   const { data: fieldSetData, isLoading: fieldSetLoading } =
//     useFetchDetailsFieldSets();
//   const { data: categoryFieldSetData, isLoading: categoryFieldSetLoading } =
//     useFetchCategoryFieldSets();

//   const filteredCategoryFieldSets = useMemo(() => {
//     if (!categoryFieldSetData || !categoryId) return [];
//     return categoryFieldSetData?.data?.data?.filter(
//       (set) => set.categoryId === categoryId
//     );
//   }, [categoryFieldSetData, categoryId]);

//   const fieldSetIds = useMemo(() => {
//     return filteredCategoryFieldSets?.flatMap((set) =>
//       set.fieldSets.map((fs) => fs.fieldsetId)
//     );
//   }, [filteredCategoryFieldSets]);

//   const filteredFields = useMemo(() => {
//     if (!fieldSetData || fieldSetIds.length === 0) return [];
//     return fieldSetData?.data?.data?.filter((field) =>
//       fieldSetIds.includes(field.id)
//     );
//   }, [fieldSetData, fieldSetIds]);

//   // useEffect(() => {
//   //   const initialInputs = filteredFields.flatMap((fieldSet) =>
//   //     fieldSet.fields.map((innerField) => ({
//   //       fieldSetId: fieldSet.id,
//   //       fieldId: innerField.id,
//   //       fieldValue:
//   //         innerField.type === "select" && innerField.isMultiSelect ? "" : "",
//   //       status: false,
//   //     }))
//   //   );
//   //   setInputs(initialInputs);
//   //   setValue("specifications", initialInputs);
//   // }, [filteredFields, setValue]);

//   useEffect(() => {
//     if (!filteredFields.length) return;

//     const initialInputs = filteredFields.flatMap((fieldSet) =>
//       fieldSet.fields.map((innerField) => ({
//         fieldSetId: fieldSet.id,
//         fieldId: innerField.id,
//         fieldValue:
//           innerField.type === "select" && innerField.isMultiSelect ? "" : "",
//         status: false,
//       }))
//     );

//     setInputs(initialInputs);
//     setValue("specifications", initialInputs);
//   }, [filteredFields, setValue]); // Depend on filteredFields and setValue

//   const handleInputChange = (fieldSetId, fieldId, fieldType, value) => {
//     let fieldValue;

//     if (fieldType === "multiSelect") {
//       fieldValue = Array.isArray(value)
//         ? value.map((option) => option.value).join("|")
//         : "";
//     } else {
//       fieldValue = value;
//     }

//     const updatedInputs = inputs.map((item) =>
//       item.fieldSetId === fieldSetId && item.fieldId === fieldId
//         ? { ...item, fieldValue }
//         : item
//     );

//     setInputs(updatedInputs);
//     setValue("specifications", updatedInputs);
//   };

//   // const handleInputChange = (fieldSetId, fieldId, fieldType, value) => {
//   //   let fieldValue;

//   //   if (fieldType === "multiSelect") {
//   //     // Convert multi-select options to a string joined by "|"
//   //     fieldValue = Array.isArray(value)
//   //       ? value.map((option) => option.value).join("|")
//   //       : "";
//   //   } else {
//   //     // For other field types, just use the provided value
//   //     fieldValue = value;
//   //   }

//   //   const updatedInputs = inputs.map((item) =>
//   //     item.fieldSetId === fieldSetId && item.fieldId === fieldId
//   //       ? { ...item, fieldValue }
//   //       : item
//   //   );

//   //   setInputs(updatedInputs);
//   //   setValue("specifications", updatedInputs);
//   // };

//   useEffect(() => {
//     if (state && state.specifications) {
//       const initialInputs = filteredFields.flatMap((fieldSet) =>
//         fieldSet.fields.map((innerField) => {
//           const existingSpec = state.specifications.find(
//             (spec) =>
//               spec.fieldSetId === fieldSet.id && spec.fieldId === innerField.id
//           );

//           return {
//             fieldSetId: fieldSet.id,
//             fieldId: innerField.id,
//             fieldValue: existingSpec ? existingSpec.fieldValue : "",
//             status: existingSpec ? existingSpec.status : false,
//           };
//         })
//       );

//       setInputs(initialInputs);
//       setValue("specifications", initialInputs);
//     }
//   }, [filteredFields, setValue, state]); // Depend on filteredFields, setValue, and state

//   // useEffect(() => {
//   //   if (state) {
//   //     // If `state.specification` exists, map it to populate the inputs
//   //     const initialInputs = filteredFields.flatMap((fieldSet) =>
//   //       fieldSet.fields.map((innerField) => {
//   //         const existingSpec = state?.specifications?.find(
//   //           (spec) =>
//   //             spec.fieldSetId === fieldSet.id && spec.fieldId === innerField.id
//   //         );

//   //         return {
//   //           fieldSetId: fieldSet.id,
//   //           fieldId: innerField.id,
//   //           fieldValue: existingSpec ? existingSpec.fieldValue : "",
//   //           status: existingSpec ? existingSpec.status : false,
//   //         };
//   //       })
//   //     );

//   //     setInputs(initialInputs);
//   //     setValue("specifications", initialInputs);
//   //   }
//   // }, [filteredFields, setValue, state?.specification]);

//   // if (fieldSetLoading || categoryFieldSetLoading) {
//   //   return <Spinner variant="primary" animation="border" />;
//   // }

//   return (
//     <>
//       {fieldSetLoading || categoryFieldSetLoading ? (
//         <div className="d-flex py-3 justify-content-center align-items-center">
//           <Button variant="falcon-default" size="sm" className="fs-10">
//             Loading...
//           </Button>
//         </div>
//       ) : (
//         <Row className="gx-2">
//           {filteredFields.map((fieldSet, setIndex) => (
//             <Row key={setIndex}>
//               {fieldSet.fields.length > 0 && (
//                 <Card.Header as="h6" className="bg-body-tertiary text-800 mb-2">
//                   {fieldSet.name}
//                 </Card.Header>
//               )}

//               {fieldSet.fields.map((field) => {
//                 const currentInput = inputs.find(
//                   (input) =>
//                     input.fieldSetId === fieldSet.id &&
//                     input.fieldId === field.id
//                 );
//                 const value = currentInput?.fieldValue || "";

//                 const selectedValues = value
//                   ? value.split("|").map((val) => ({ value: val, label: val }))
//                   : [];

//                 return (
//                   <Col md="4" key={field.id}>
//                     <Form.Group className="mb-3" controlId={field.id}>
//                       <Form.Label className="fs-9">{field.label}</Form.Label>

//                       {field.type === "text" ||
//                       field.type === "password" ||
//                       field.type === "date" ||
//                       field.type === "color" ||
//                       field.type === "currency" ||
//                       field.type === "email" ||
//                       field.type === "number" ||
//                       field.type === "phone" ||
//                       field.type === "time" ||
//                       field.type === "datetime-local" ? (
//                         <Form.Control
//                           type={field.type}
//                           placeholder={field.placeholder}
//                           value={value}
//                           onChange={(e) =>
//                             handleInputChange(
//                               fieldSet.id,
//                               field.id,
//                               field.type,
//                               e.target.value
//                             )
//                           }
//                         />
//                       ) : null}

//                       {field.type === "select" && (
//                         <Form.Select
//                           value={value}
//                           onChange={(e) =>
//                             handleInputChange(
//                               fieldSet.id,
//                               field.id,
//                               "select",
//                               e.target.value
//                             )
//                           }
//                         >
//                           <option value="">Select</option>
//                           {field.listValues.split("|").map((option, index) => (
//                             <option key={index} value={option}>
//                               {option}
//                             </option>
//                           ))}
//                         </Form.Select>
//                       )}

//                       {field.type === "multiSelect" && (
//                         <Select
//                           closeMenuOnSelect={false}
//                           options={field.listValues
//                             .split("|")
//                             .map((value) => ({ value, label: value }))}
//                           placeholder="Select..."
//                           isMulti
//                           classNamePrefix="react-select"
//                           value={selectedValues}
//                           onChange={(selectedOptions) =>
//                             handleInputChange(
//                               fieldSet.id,
//                               field.id,
//                               "multiSelect",
//                               selectedOptions
//                             )
//                           }
//                         />
//                       )}

//                       {field.type === "checkbox" && (
//                         <Form.Check
//                           type="checkbox"
//                           label={field.label}
//                           checked={value === "true"}
//                           onChange={(e) =>
//                             handleInputChange(
//                               fieldSet.id,
//                               field.id,
//                               "checkbox",
//                               e.target.checked ? "true" : "false"
//                             )
//                           }
//                         />
//                       )}

//                       {field.type === "radio" && (
//                         <div>
//                           {field.listValues.split("|").map((option, index) => (
//                             <Form.Check
//                               type="radio"
//                               name={field.id}
//                               key={index}
//                               value={option}
//                               label={option}
//                               checked={value === option}
//                               onChange={(e) =>
//                                 handleInputChange(
//                                   fieldSet.id,
//                                   field.id,
//                                   "radio",
//                                   e.target.value
//                                 )
//                               }
//                             />
//                           ))}
//                         </div>
//                       )}
//                       {field.type === "textarea" && (
//                         <Form.Control
//                           as="textarea"
//                           placeholder={field.placeholder}
//                           value={value}
//                           onChange={(e) =>
//                             handleInputChange(
//                               fieldSet.id,
//                               field.id,
//                               "textarea",
//                               e.target.value
//                             )
//                           }
//                         />
//                       )}
//                     </Form.Group>
//                   </Col>
//                 );
//               })}
//             </Row>
//           ))}
//         </Row>
//       )}
//     </>
//   );
// };

// export default Specifications;

import React, { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import { useFetchDetailsFieldSets } from "hooks/useFetchDetailFieldSets";
import { useFetchCategoryFieldSets } from "hooks/useCategoryFieldSet";

const Specifications = () => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();
  const categoryId = watch("categoryId");
  const { state } = useLocation();
  const [inputs, setInputs] = useState([]);

  const { data: fieldSetData, isLoading: fieldSetLoading } =
    useFetchDetailsFieldSets();
  const { data: categoryFieldSetData, isLoading: categoryFieldSetLoading } =
    useFetchCategoryFieldSets();

  // Filter category field sets based on categoryId
  const filteredCategoryFieldSets = useMemo(() => {
    if (!categoryFieldSetData || !categoryId) return [];
    return categoryFieldSetData.data.data.filter(
      (set) => set.categoryId === categoryId
    );
  }, [categoryFieldSetData, categoryId]);

  // Get field set IDs from filtered category field sets
  const fieldSetIds = useMemo(() => {
    return filteredCategoryFieldSets.flatMap((set) =>
      set.fieldSets.map((fs) => fs.fieldsetId)
    );
  }, [filteredCategoryFieldSets]);

  // Filter fields based on field set IDs
  const filteredFields = useMemo(() => {
    if (!fieldSetData || fieldSetIds.length === 0) return [];
    return fieldSetData.data.data.filter((field) =>
      fieldSetIds.includes(field.id)
    );
  }, [fieldSetData, fieldSetIds]);

  // Update `inputs` and form context when `filteredFields` changes
  useEffect(() => {
    if (!filteredFields || filteredFields.length === 0) return;

    const initialInputs = filteredFields.flatMap((fieldSet) =>
      fieldSet.fields.map((innerField) => ({
        fieldSetId: fieldSet.id,
        fieldId: innerField.id,
        fieldValue: "",
        status: false,
      }))
    );

    setInputs(initialInputs);
    setValue("specifications", initialInputs);
  }, [filteredFields, setValue]);

  // Handle initialization with existing state specifications
  useEffect(() => {
    if (state && state.specifications) {
      const initialInputs = filteredFields.flatMap((fieldSet) =>
        fieldSet.fields.map((innerField) => {
          const existingSpec = state.specifications.find(
            (spec) =>
              spec.fieldSetId === fieldSet.id && spec.fieldId === innerField.id
          );

          return {
            fieldSetId: fieldSet.id,
            fieldId: innerField.id,
            fieldValue: existingSpec ? existingSpec.fieldValue : "",
            status: existingSpec ? existingSpec.status : false,
          };
        })
      );

      setInputs(initialInputs);
      setValue("specifications", initialInputs);
    }
  }, [filteredFields, setValue, state?.specifications]);

  // Handle input changes and update state and form context
  const handleInputChange = (fieldSetId, fieldId, fieldType, value) => {
    let fieldValue;

    if (fieldType === "multiSelect") {
      fieldValue = Array.isArray(value)
        ? value.map((option) => option.value).join("|")
        : "";
    } else {
      fieldValue = value;
    }

    const updatedInputs = inputs.map((item) =>
      item.fieldSetId === fieldSetId && item.fieldId === fieldId
        ? { ...item, fieldValue }
        : item
    );

    setInputs(updatedInputs);
    setValue("specifications", updatedInputs);
  };

  // Show loading spinner while data is loading
  if (fieldSetLoading || categoryFieldSetLoading) {
    return (
      <div className="d-flex py-3 justify-content-center align-items-center">
        <Button variant="falcon-default" size="sm" className="fs-10" disabled>
          Loading...
        </Button>
      </div>
    );
  }

  return (
    <Row className="gx-2">
      {filteredFields.map((fieldSet, setIndex) => (
        <Row key={setIndex}>
          {fieldSet.fields.length > 0 && (
            <Card.Header as="h6" className="bg-body-tertiary text-800 mb-2">
              {fieldSet.name}
            </Card.Header>
          )}

          {fieldSet.fields.map((field) => {
            const currentInput = inputs.find(
              (input) =>
                input.fieldSetId === fieldSet.id && input.fieldId === field.id
            );
            const value = currentInput?.fieldValue || "";

            const selectedValues = value
              ? value.split("|").map((val) => ({ value: val, label: val }))
              : [];

            return (
              <Col md="4" key={field.id}>
                <Form.Group className="mb-3" controlId={field.id}>
                  <Form.Label className="fs-9">{field.label}</Form.Label>

                  {[
                    "text",
                    "password",
                    "date",
                    "color",
                    "currency",
                    "email",
                    "number",
                    "phone",
                    "time",
                    "datetime-local",
                  ].includes(field.type) && (
                    <Form.Control
                      type={field.type}
                      placeholder={field.placeholder}
                      value={value}
                      onChange={(e) =>
                        handleInputChange(
                          fieldSet.id,
                          field.id,
                          field.type,
                          e.target.value
                        )
                      }
                    />
                  )}

                  {field.type === "select" && (
                    <Form.Select
                      value={value}
                      onChange={(e) =>
                        handleInputChange(
                          fieldSet.id,
                          field.id,
                          "select",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      {field.listValues.split("|").map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Select>
                  )}

                  {field.type === "multiSelect" && (
                    <Select
                      closeMenuOnSelect={false}
                      options={field.listValues
                        .split("|")
                        .map((value) => ({ value, label: value }))}
                      placeholder="Select..."
                      isMulti
                      classNamePrefix="react-select"
                      value={selectedValues}
                      onChange={(selectedOptions) =>
                        handleInputChange(
                          fieldSet.id,
                          field.id,
                          "multiSelect",
                          selectedOptions
                        )
                      }
                    />
                  )}

                  {field.type === "checkbox" && (
                    <Form.Check
                      type="checkbox"
                      label={field.label}
                      checked={value === "true"}
                      onChange={(e) =>
                        handleInputChange(
                          fieldSet.id,
                          field.id,
                          "checkbox",
                          e.target.checked ? "true" : "false"
                        )
                      }
                    />
                  )}

                  {field.type === "radio" && (
                    <div>
                      {field.listValues.split("|").map((option, index) => (
                        <Form.Check
                          type="radio"
                          name={field.id}
                          key={index}
                          value={option}
                          label={option}
                          checked={value === option}
                          onChange={(e) =>
                            handleInputChange(
                              fieldSet.id,
                              field.id,
                              "radio",
                              e.target.value
                            )
                          }
                        />
                      ))}
                    </div>
                  )}

                  {field.type === "textarea" && (
                    <Form.Control
                      as="textarea"
                      placeholder={field.placeholder}
                      value={value}
                      onChange={(e) =>
                        handleInputChange(
                          fieldSet.id,
                          field.id,
                          "textarea",
                          e.target.value
                        )
                      }
                    />
                  )}
                </Form.Group>
              </Col>
            );
          })}
        </Row>
      ))}
    </Row>
  );
};

export default Specifications;
