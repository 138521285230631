import { useQuery } from "@tanstack/react-query";
import { CategoriesByIdAPI, RolePermissionsAPI } from "api";

const fetchData = (categoryId) => {
  return CategoriesByIdAPI.get({ params: { categoryId } });
};
export const useFetchCategoriesById = (categoryId) => {
  return useQuery({
    queryKey: ["categories-by-id", categoryId],
    queryFn: () => fetchData(categoryId),
  });
};
