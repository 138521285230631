import axios from "axios";
// axios.defaults.baseURL = process.env.REACT_APP_URL;

class _LoginApi {
  uri;

  constructor(uri) {
    this.uri = uri;
  }

  post = async (data) => {
    return await axios.post(this.uri, data);
  };
}

export const LoginAPI = new _LoginApi(`/Authentication/Authenticate`);
