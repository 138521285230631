import { useAppContext } from "Main";
import { jwtDecode } from "jwt-decode";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";
import {
  parseISO,
  formatDistanceToNow,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  isAfter,
  differenceInSeconds,
  isBefore,
} from "date-fns";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";

export default function AuthGuard() {
  const { state } = useAppContext();
  const { pathname: path } = useLocation();
  let { token, isLoading } = state;

  const tokenExpiry = localStorage.getItem("tokenExpiry");
  const userFound = localStorage.getItem("user");
  const menus = localStorage.getItem("menus");

  // console.log(tokenExpiry);

  const isTokenExpired = (tokenParameter) => {
    if (!tokenParameter) return true;

    const givenDateString = tokenExpiry ? tokenExpiry : "";
    // const givenDateString = "2024-05-23T06:56:04.4759098-04:00";

    // Parse the given date to a Date object
    const givenDate = parseISO(givenDateString);

    // console.log(givenDate);

    // Get the current date
    const currentDate = new Date();
    // console.log(currentDate);

    const isGivenDateFuture = isAfter(givenDate, currentDate);
    const isGivenDatePast = isBefore(givenDate, currentDate);

    // console.log(isGivenDateFuture);

    // Calculate the differences
    // const daysDifference = differenceInDays(givenDate, currentDate);
    // const hoursDifference = differenceInHours(givenDate, currentDate) % 24;
    // const minutesDifference = differenceInMinutes(givenDate, currentDate) % 60;
    // const secondsDifference = differenceInSeconds(givenDate, currentDate) % 60;

    // let date = new Date().toISOString();
    try {
      // return tokenExpiry < date;
      return isGivenDatePast;
    } catch (error) {
      console.log(error);
      return true;
    }
    // try {
    //   let decodedToken = jwtDecode(tokenParameter);
    //   decodedToken.exp = decodedToken.exp * 1000;
    //   // decodedToken.exp = 1716281160000;
    //   const currentTime = Date.now();
    //   // console.log(decodedToken.exp * 1000 === Date.now());
    //   // const currentTime = 1716253950000 / 1000;
    //   return decodedToken.exp < currentTime;
    // } catch (error) {
    //   console.error("Error decoding token:", error);
    //   return true;
    // }
  };

  if (isLoading) {
    return "Loading";
  }
  if (token && path == "/login") {
    return <Navigate to={"/"} />;
  }
  if (!token && path != "/login") {
    return <Navigate to={"login"} />;
  }
  if (token && isTokenExpired(token)) {
    localStorage.clear();
    toast.info("Your session is expired, login again.", {
      position: customSettings.toastPosition,
      autoClose: customSettings.toastClose,
    });
    return <Navigate to={"login"} />;
  }
  if (!userFound) {
    localStorage.clear();

    return <Navigate to={"login"} />;
  }
  // if (!menus) {
  //   localStorage.clear();
  //   return <Navigate to={"login"} />;
  // }
  return <Outlet />;
}
