// import React, { useContext, useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import { Card, Form, Nav, ProgressBar } from "react-bootstrap";
// import classNames from "classnames";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useForm } from "react-hook-form";
// import AccountForm from "./StepOneForm";
// import PersonalForm from "./PersonalForm";
// import BillingForm from "./BillingForm";
// import Success from "./Success";
// import { AuthWizardContext } from "context/Context";
// import IconButton from "components/common/IconButton";
// import WizardModal from "./WizardModal";
// import Flex from "components/common/Flex";
// import { useAppContext } from "Main";
// import StepOneForm from "./StepOneForm";
// import StepTwoForm from "./StepTwoForm";

// const WizardLayout = ({ variant, validation, progressBar }) => {
//   const { isRTL } = useAppContext();
//   const { user, setUser, step, setStep } = useContext(AuthWizardContext);
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     watch,
//     setValue,
//     reset,
//     clearErrors,
//     trigger,
//   } = useForm();

//   const [modal, setModal] = useState(false);

//   const [isBoxVisible, setIsBoxVisible] = useState(false);
//   const [selectedItemStep1, setSelectedItemStep1] = useState(null);
//   const [selectedItemStep2, setSelectedItemStep2] = useState(null);

//   // Function to toggle the visibility of the box
//   const toggleBoxVisibility = () => {
//     setIsBoxVisible(!isBoxVisible);
//   };

//   // const navItems = [
//   //   {
//   //     icon: "lock",
//   //     label: "Step 1",
//   //   },
//   //   {
//   //     icon: "user",
//   //     label: "Step 2",
//   //   },
//   //   // {
//   //   //   icon: "dollar-sign",
//   //   //   label: "Step 3",
//   //   // },
//   //   {
//   //     icon: "thumbs-up",
//   //     label: "Done",
//   //   },
//   // ];

//   const [navItems, setNavItems] = useState([
//     {
//       icon: "lock",
//       label: "Step 1",
//     },
//     {
//       icon: "user",
//       label: "Step 2",
//     },
//     // {
//     //   icon: "dollar-sign",
//     //   label: "Step 3",
//     // },
//     {
//       icon: "thumbs-up",
//       label: "Done",
//     },
//   ]);

//   useEffect(() => {
//     if (selectedItemStep2) {
//       setNavItems((prevItems) => {
//         // Check if "Step 3" already exists
//         const hasStep3 = prevItems.some((item) => item.label === "Step 3");

//         if (!hasStep3) {
//           // Insert "Step 3" before "Done"
//           return prevItems
//             .map((item) =>
//               item.label === "Done"
//                 ? [{ icon: "user", label: "Step 3" }, item]
//                 : item
//             )
//             .flat();
//         }

//         return prevItems;
//       });
//     }
//   }, [selectedItemStep2]);

//   console.log(navItems);

//   const onSubmitData = async (data) => {
//     if (step === 1 && !data.categoryGroup) {
//       await trigger("categoryGroup");
//       return;
//     }
//     setUser({ ...user, ...data });
//     setStep(step + 1);

//     // setUser({ ...user, ...data });
//     // setStep(step + 1);
//   };
//   const onError = () => {
//     if (!validation) {
//       clearErrors();
//       setStep(step + 1);
//     }
//   };

//   const toggle = () => setModal(!modal);

//   const handleNavs = (targetStep) => {
//     console.log(targetStep);
//     if (step !== 4) {
//       if (targetStep < step) {
//         setStep(targetStep);
//       } else {
//         handleSubmit(onSubmitData, onError)();
//       }
//     } else {
//       toggle();
//     }
//   };

//   return (
//     <>
//       <button
//         className="btn btn-primary rounded-circle position-fixed"
//         style={{
//           bottom: "20px",
//           right: "20px",
//           width: "50px",
//           height: "50px",
//           zIndex: 1000, // Ensure the button is on top
//         }}
//         onClick={toggleBoxVisibility}
//       >
//         +
//       </button>
//       {/* <WizardModal modal={modal} setModal={setModal} /> */}
//       <Card
//         as={Form}
//         noValidate
//         onSubmit={handleSubmit(onSubmitData, onError)}
//         className="theme-wizard mb-5"
//       >
//         <Card.Header
//           className={classNames("bg-body-tertiary", {
//             "px-4 py-3": variant === "pills",
//             "pb-2": !variant,
//           })}
//         >
//           <Nav className="justify-content-center" variant={variant}>
//             {variant === "pills"
//               ? navItems.map((item, index) => (
//                   <NavItemPill
//                     key={item.label}
//                     index={index + 1}
//                     step={step}
//                     handleNavs={handleNavs}
//                     icon={item.icon}
//                     label={item.label}
//                   />
//                 ))
//               : navItems.map((item, index) => (
//                   <NavItem
//                     key={item.label}
//                     index={index + 1}
//                     step={step}
//                     handleNavs={handleNavs}
//                     icon={item.icon}
//                     label={item.label}
//                   />
//                 ))}
//           </Nav>
//         </Card.Header>
//         {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
//         <Card.Body className="fw-normal px-md-6 py-4">
//           {step === 1 && (
//             <StepOneForm
//               register={register}
//               errors={errors}
//               setValue={setValue}
//               clearErrors={clearErrors}
//               selectedItem={selectedItemStep1}
//               setSelectedItem={(item) => {
//                 setSelectedItemStep1(item);
//                 setStep((step) => step + 1); // Move to next step
//               }}
//             />
//           )}
//           {step === 2 && (
//             <StepTwoForm
//               register={register}
//               errors={errors}
//               setValue={setValue}
//               clearErrors={clearErrors}
//               data={selectedItemStep1}
//               selectedItem={selectedItemStep2}
//               setSelectedItem={(item) => {
//                 setSelectedItemStep2(item);
//                 setStep((step) => step + 1);
//               }}
//             />
//           )}
//           {/* {step === 3 && (
//             <BillingForm
//               register={register}
//               errors={errors}
//               setValue={setValue}
//             />
//           )} */}
//           {step === 4 && <Success reset={reset} />}
//         </Card.Body>
//         <Card.Footer
//           className={classNames("px-md-6 bg-body-tertiary", {
//             "d-none": step === 4,
//             " d-flex": step < 4,
//           })}
//         >
//           <IconButton
//             variant="link"
//             icon={isRTL ? "chevron-right" : "chevron-left"}
//             iconAlign="left"
//             transform="down-1 shrink-4"
//             className={classNames("px-0 fw-semibold", {
//               "d-none": step === 1,
//             })}
//             onClick={() => {
//               setStep(step - 1);
//             }}
//           >
//             Prev
//           </IconButton>

//           <IconButton
//             variant="primary"
//             className="ms-auto px-5"
//             type="submit"
//             icon={isRTL ? "chevron-left" : "chevron-right"}
//             iconAlign="right"
//             transform="down-1 shrink-4"
//           >
//             Next
//           </IconButton>
//         </Card.Footer>
//       </Card>

//       {isBoxVisible && (
//         <div
//           className="position-fixed bg-white border p-3"
//           style={{
//             bottom: "80px",
//             right: "20px",
//             width: "90%", // Responsive width for smaller screens
//             maxWidth: "300px", // Maximum width for larger screens
//             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//             borderRadius: "8px",
//             zIndex: 999, // Ensure the box is above other content
//           }}
//         >
//           <p>This is Floating Windows Content!</p>
//         </div>
//       )}
//     </>
//   );
// };

// const NavItem = ({ index, step, handleNavs, icon, label }) => {
//   return (
//     <Nav.Item>
//       <Nav.Link
//         className={classNames("fw-semibold", {
//           done: index < 4 ? step > index : step > 3,
//           active: step === index,
//         })}
//         onClick={() => handleNavs(index)}
//       >
//         <span className="nav-item-circle-parent">
//           <span className="nav-item-circle">
//             <FontAwesomeIcon icon={icon} />
//           </span>
//         </span>
//         <span className="d-none d-md-block mt-1 fs-10">{label}</span>
//       </Nav.Link>
//     </Nav.Item>
//   );
// };

// const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
//   return (
//     <Nav.Item>
//       <Nav.Link
//         className={classNames("fw-semibold", {
//           done: step > index,
//           active: step === index,
//         })}
//         onClick={() => handleNavs(index)}
//       >
//         <Flex alignItems="center" justifyContent="center">
//           <FontAwesomeIcon icon={icon} />
//           <span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
//         </Flex>
//       </Nav.Link>
//     </Nav.Item>
//   );
// };

// WizardLayout.propTypes = {
//   variant: PropTypes.oneOf(["pills"]),
//   validation: PropTypes.bool,
//   progressBar: PropTypes.bool,
// };

// NavItemPill.propTypes = {
//   index: PropTypes.number.isRequired,
//   step: PropTypes.number.isRequired,
//   handleNavs: PropTypes.func.isRequired,
//   icon: PropTypes.string.isRequired,
//   label: PropTypes.string.isRequired,
// };

// NavItem.propTypes = NavItemPill.propTypes;

// export default WizardLayout;

// import React, { useState, useEffect, useContext } from "react";
// import { Card, Form, Nav, ProgressBar, Spinner } from "react-bootstrap";
// import classNames from "classnames";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useForm } from "react-hook-form";
// import { AuthWizardContext } from "context/Context";
// import IconButton from "components/common/IconButton";
// import { useAppContext } from "Main";
// import DynamicStepForm from "./DynamicStepForm";
// import Flex from "components/common/Flex";
// import StepOneForm from "./StepOneForm";
// import StepTwoForm from "./StepTwoForm";
// import Success from "./Success";
// import { useFetchCategoriesById } from "hooks/useFetchCategoriesById";

// const WizardLayout = ({ variant, validation, progressBar }) => {
//   const { isRTL } = useAppContext();
//   const { user, setUser, step, setStep } = useContext(AuthWizardContext);
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     watch,
//     setValue,
//     reset,
//     clearErrors,
//     trigger,
//   } = useForm();

//   const [modal, setModal] = useState(false);
//   const [isBoxVisible, setIsBoxVisible] = useState(false);
//   const [stepsData, setStepsData] = useState({});
//   const [navItems, setNavItems] = useState([]);
//   const [data, setData] = useState([]); // Use your data here
//   const [selectedItemStep1, setSelectedItemStep1] = useState(null);
//   const [selectedItemStep2, setSelectedItemStep2] = useState(null);

//   const { data: categoriesById, isLoading: categoriesByIdLoading } =
//     useFetchCategoriesById(selectedItemStep2?.categoryId);

//   useEffect(() => {
//     if (categoriesById) {
//       setData(categoriesById?.data?.data || []);
//     }
//   }, [categoriesById]);

//   useEffect(() => {
//     // Process and create dynamic steps when data changes
//     const processedData = processData(data);
//     const maxCLevel = getMaxCLevel(data);

//     const dynamicSteps = createSteps(maxCLevel);

//     setStepsData(processedData);
//     setNavItems([
//       { icon: "lock", label: "Step 1", index: 1 },
//       { icon: "user", label: "Step 2", index: 2 },
//       ...dynamicSteps,
//       { icon: "thumbs-up", label: "Done", index: dynamicSteps.length + 3 },
//     ]);
//   }, [data]);

//   const onSubmitData = async (formData) => {
//     if (step === 1 && !formData.categoryGroup) {
//       await trigger("categoryGroup");
//       return;
//     }
//     setUser({ ...user, ...formData });
//     setStep(step + 1);
//   };

//   const onError = () => {
//     if (!validation) {
//       clearErrors();
//       setStep(step + 1);
//     }
//   };

//   const handleNavs = (targetStep) => {
//     if (step !== navItems.length) {
//       if (targetStep < step) {
//         setStep(targetStep);
//       } else {
//         handleSubmit(onSubmitData, onError)();
//       }
//     } else {
//       // toggle();
//     }
//   };

//   const toggleBoxVisibility = () => {
//     setIsBoxVisible(!isBoxVisible);
//   };

//   if (categoriesByIdLoading) {
//     return <Spinner variant="primary" animation="border" />;
//   }

//   return (
//     <>
//       <button
//         className="btn btn-primary rounded-circle position-fixed"
//         style={{
//           bottom: "20px",
//           right: "20px",
//           width: "50px",
//           height: "50px",
//           zIndex: 1000,
//         }}
//         onClick={toggleBoxVisibility}
//       >
//         +
//       </button>
//       <Card
//         as={Form}
//         noValidate
//         onSubmit={handleSubmit(onSubmitData, onError)}
//         className="theme-wizard mb-5"
//       >
//         <Card.Header
//           className={classNames("bg-body-tertiary", {
//             "px-4 py-3": variant === "pills",
//             "pb-2": !variant,
//           })}
//         >
//           <Nav className="justify-content-center" variant={variant}>
//             {variant === "pills"
//               ? navItems.map((item) => (
//                   <NavItemPill
//                     key={item.index}
//                     index={item.index}
//                     step={step}
//                     handleNavs={handleNavs}
//                     icon={item.icon}
//                     label={item.label}
//                   />
//                 ))
//               : navItems.map((item) => (
//                   <NavItem
//                     key={item.index}
//                     index={item.index}
//                     step={step}
//                     handleNavs={handleNavs}
//                     icon={item.icon}
//                     label={item.label}
//                   />
//                 ))}
//           </Nav>
//         </Card.Header>
//         {progressBar && (
//           <ProgressBar
//             now={(step / navItems.length) * 100}
//             style={{ height: 2 }}
//           />
//         )}
//         <Card.Body className="fw-normal px-md-6 py-4">
//           {step === 1 && (
//             <StepOneForm
//               register={register}
//               errors={errors}
//               setValue={setValue}
//               clearErrors={clearErrors}
//               selectedItem={selectedItemStep1}
//               setSelectedItem={(item) => {
//                 setSelectedItemStep1(item);
//                 setStep((step) => step + 1); // Move to next step
//               }}
//             />
//           )}
//           {step === 2 && (
//             <StepTwoForm
//               register={register}
//               errors={errors}
//               setValue={setValue}
//               clearErrors={clearErrors}
//               data={selectedItemStep1}
//               selectedItem={selectedItemStep2}
//               setSelectedItem={(item) => {
//                 setSelectedItemStep2(item);
//                 // API call to fetch data for dynamic steps
//                 setStep((step) => step + 1); // Move to next step
//               }}
//             />
//           )}
//           {step > 2 && step <= navItems.length - 1 && (
//             <DynamicStepForm
//               step={step}
//               data={stepsData[step - 2]}
//               register={register}
//               errors={errors}
//               setValue={setValue}
//               clearErrors={clearErrors}
//             />
//           )}
//           {step === navItems.length && <Success reset={reset} />}
//         </Card.Body>
//         <Card.Footer
//           className={classNames("px-md-6 bg-body-tertiary", {
//             "d-none": step === navItems.length,
//             "d-flex": step < navItems.length,
//           })}
//         >
//           <IconButton
//             variant="link"
//             icon={isRTL ? "chevron-right" : "chevron-left"}
//             iconAlign="left"
//             transform="down-1 shrink-4"
//             className={classNames("px-0 fw-semibold", { "d-none": step === 1 })}
//             onClick={() => setStep(step - 1)}
//           >
//             Prev
//           </IconButton>
//           <IconButton
//             variant="primary"
//             className="ms-auto px-5"
//             type="submit"
//             icon={isRTL ? "chevron-left" : "chevron-right"}
//             iconAlign="right"
//             transform="down-1 shrink-4"
//           >
//             Next
//           </IconButton>
//         </Card.Footer>
//       </Card>
//       {isBoxVisible && (
//         <div
//           className="position-fixed bg-white border p-3"
//           style={{
//             bottom: "80px",
//             right: "20px",
//             width: "90%",
//             maxWidth: "300px",
//             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
//             borderRadius: "8px",
//             zIndex: 999,
//           }}
//         >
//           <p>This is Floating Windows Content!</p>
//         </div>
//       )}
//     </>
//   );
// };

// const NavItem = ({ index, step, handleNavs, icon, label }) => (
//   <Nav.Item>
//     <Nav.Link
//       className={classNames("fw-semibold", {
//         done: index < 4 ? step > index : step > 3,
//         active: step === index,
//       })}
//       onClick={() => handleNavs(index)}
//     >
//       <span className="nav-item-circle-parent">
//         <span className="nav-item-circle">
//           <FontAwesomeIcon icon={icon} />
//         </span>
//       </span>
//       <span className="d-none d-md-block mt-1 fs-10">{label}</span>
//     </Nav.Link>
//   </Nav.Item>
// );

// const NavItemPill = ({ index, step, handleNavs, icon, label }) => (
//   <Nav.Item>
//     <Nav.Link
//       className={classNames("fw-semibold", {
//         done: step > index,
//         active: step === index,
//       })}
//       onClick={() => handleNavs(index)}
//     >
//       <Flex alignItems="center" justifyContent="center">
//         <FontAwesomeIcon icon={icon} />
//         <span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
//       </Flex>
//     </Nav.Link>
//   </Nav.Item>
// );

// const processData = (data) => {
//   const steps = {};
//   data.forEach((item) => {
//     const level = item.cLevel;
//     if (level >= 2) {
//       if (!steps[level]) {
//         steps[level] = [];
//       }
//       steps[level].push(item);
//     }
//   });
//   return steps;
// };

// // Get maximum cLevel
// const getMaxCLevel = (data) => {
//   return Math.max(...data.map((item) => item.cLevel), 2); // Default to 2 if no valid levels
// };

// // Create steps based on maxLevel starting from Step 3
// const createSteps = (maxLevel) => {
//   const steps = [];
//   for (let i = 2; i <= maxLevel; i++) {
//     // Start from cLevel 2
//     steps.push({ label: `Step ${i + 1}`, index: i + 2 }); // Adjust index to start from 3
//   }
//   return steps;
// };

// export default WizardLayout;

import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  ProgressBar,
  Row,
  Spinner,
} from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { AuthWizardContext } from "context/Context";
import IconButton from "components/common/IconButton";
import { useAppContext } from "Main";
import DynamicStepForm from "./DynamicStepForm";
import Flex from "components/common/Flex";
import StepOneForm from "./StepOneForm";
import StepTwoForm from "./StepTwoForm";
import Success from "./Success";
import { useFetchCategoriesById } from "hooks/useFetchCategoriesById";
import Lottie from "lottie-react";
import celebration from "./lottie/celebration.json";

const WizardLayout = ({ variant, validation, progressBar }) => {
  const { isRTL } = useAppContext();
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors,
    trigger,
  } = useForm();

  const [modal, setModal] = useState(false);
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [stepsData, setStepsData] = useState({});
  const [navItems, setNavItems] = useState([]);
  const [data, setData] = useState([]); // Use your data here
  const [selectedItemStep1, setSelectedItemStep1] = useState(null);
  const [selectedItemStep2, setSelectedItemStep2] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedItemDynamic, setSelectedItemDynamic] = useState(null);

  const { data: categoriesById, isLoading: categoriesByIdLoading } =
    useFetchCategoriesById(selectedItemStep2?.categoryId);

  useEffect(() => {
    if (categoriesById) {
      setData(categoriesById?.data?.data || []);
    }
  }, [categoriesById]);

  useEffect(() => {
    // Process and create dynamic steps when data changes
    const processedData = processData(data);
    const maxCLevel = getMaxCLevel(data);
    const dynamicSteps = createSteps(maxCLevel);

    setStepsData(processedData);
    setNavItems([
      { icon: "lock", label: "Step 1", index: 1 },
      { icon: "user", label: "Step 2", index: 2 },
      ...dynamicSteps,
      { icon: "thumbs-up", label: "Done", index: dynamicSteps.length + 3 },
    ]);
  }, [data]);

  const onSubmitData = async (formData) => {
    if (step === 1 && !formData.categoryGroup) {
      await trigger("categoryGroup");
      return;
    }
    setUser({ ...user, ...formData });
    setStep(step + 1);
  };

  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const handleNavs = (targetStep) => {
    if (step !== navItems.length) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      // toggle();
    }
  };

  const toggleBoxVisibility = () => {
    setIsBoxVisible(!isBoxVisible);
  };

  const emptyData = () => {
    setStep(1);
    setUser({});
    setData([]);
    setStepsData(null);
    reset();
  };

  if (categoriesByIdLoading) {
    return <Spinner variant="primary" animation="border" />;
  }

  return (
    <>
      <button
        className="btn btn-primary rounded-circle position-fixed"
        style={{
          bottom: "20px",
          right: "20px",
          width: "50px",
          height: "50px",
          zIndex: 1000,
        }}
        onClick={toggleBoxVisibility}
      >
        +
      </button>
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames("bg-body-tertiary", {
            "px-4 py-3": variant === "pills",
            "pb-2": !variant,
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === "pills"
              ? navItems.map((item) => (
                  <NavItemPill
                    key={item.index}
                    navItems={navItems}
                    index={item.index}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item) => (
                  <NavItem
                    navItems={navItems}
                    key={item.index}
                    index={item.index}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && (
          <ProgressBar
            now={(step / navItems.length) * 100}
            style={{ height: 2 }}
          />
        )}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <StepOneForm
              register={register}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              selectedItem={selectedItemStep1}
              setSelectedItem={(item) => {
                setSelectedItemStep1(item);
                setStep((step) => step + 1); // Move to next step
              }}
            />
          )}
          {step === 2 && (
            <StepTwoForm
              register={register}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              data={selectedItemStep1}
              selectedItem={selectedItemStep2}
              setSelectedItem={(item) => {
                setSelectedItemStep2(item);
                setStep((step) => step + 1); // Move to next step
              }}
            />
          )}
          {step > 2 && step <= navItems.length - 1 && (
            <DynamicStepForm
              step={step}
              data={
                step === 3
                  ? stepsData[step - 1]
                  : data.filter((d) => d.parent === selectedItemDynamic?.id)
              }
              // data={getFilteredData()}
              register={register}
              errors={errors}
              setValue={setValue}
              clearErrors={clearErrors}
              selectedItemDynamic={selectedItemDynamic}
              setSelectedItemDynamic={(item) => {
                setSelectedItemDynamic(item);
                setStep((step) => step + 1);
              }}
            />
          )}
          {
            step === navItems.length && (
              <>
                <Row>
                  <Col className="text-center">
                    <div className="wizard-lottie-wrapper">
                      <div className="wizard-lottie mx-auto">
                        <Lottie animationData={celebration} loop={true} />
                      </div>
                    </div>
                    {/* <h4 className="mb-1">Your account is all set!</h4>
          <p className="fs-9">Now you can access to your account</p> */}
                    <Button
                      color="primary"
                      className="px-5 my-3"
                      onClick={emptyData}
                    >
                      Start Over
                    </Button>
                  </Col>
                </Row>
              </>
            )
            // <Success reset={reset} />
          }
        </Card.Body>
        <Card.Footer
          className={classNames("px-md-6 bg-body-tertiary", {
            "d-none": step === navItems.length,
            "d-flex": step < navItems.length,
          })}
        >
          <IconButton
            variant="link"
            icon={isRTL ? "chevron-right" : "chevron-left"}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames("px-0 fw-semibold", { "d-none": step === 1 })}
            onClick={() => setStep(step - 1)}
          >
            Prev
          </IconButton>
          <IconButton
            variant="primary"
            className="ms-auto px-5"
            type="submit"
            icon={isRTL ? "chevron-left" : "chevron-right"}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            Next
          </IconButton>
        </Card.Footer>
      </Card>
      {isBoxVisible && (
        <div
          className="position-fixed bg-white border p-3"
          style={{
            bottom: "80px",
            right: "20px",
            width: "90%",
            maxWidth: "300px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            zIndex: 999,
          }}
        >
          <p>This is Floating Windows Content!</p>
        </div>
      )}
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label, navItems }) => (
  <Nav.Item>
    <Nav.Link
      className={classNames("fw-semibold", {
        done: index < 4 ? step > index : step > 3,
        active: step === index,
      })}
      onClick={() => handleNavs(index)}
    >
      <span className="nav-item-circle-parent">
        <span className="nav-item-circle">
          <FontAwesomeIcon icon={icon} />
        </span>
      </span>
      <span className="d-none d-md-block mt-1 fs-10">{label}</span>
    </Nav.Link>
  </Nav.Item>
);

const NavItemPill = ({ index, step, handleNavs, icon, label }) => (
  <Nav.Item>
    <Nav.Link
      className={classNames("fw-semibold", {
        done: step > index,
        active: step === index,
      })}
      onClick={() => handleNavs(index)}
    >
      <Flex alignItems="center" justifyContent="center">
        <FontAwesomeIcon icon={icon} />
        <span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
      </Flex>
    </Nav.Link>
  </Nav.Item>
);

const processData = (data) => {
  const steps = {};
  data
    .filter((d) => d.cLevel > 1)
    .forEach((item) => {
      const level = item.cLevel;
      if (!steps[level]) {
        steps[level] = [];
      }
      steps[level].push(item);
    });
  return steps;
};

// const getMaxCLevel = (data) => {
//   const filteredData = data.filter((item) => item.cLevel > 1);
//   return Math.max(...filteredData.map((item) => item.cLevel), 0); // Default to 1 if no valid levels
// };

const getMaxCLevel = (data) => {
  const filteredData = data.filter((item) => item.cLevel > 1); // Exclude cLevel 1
  return filteredData.length > 0
    ? Math.max(...filteredData.map((item) => item.cLevel))
    : 0;
};

const createSteps = (maxLevel) => {
  const steps = [];
  let stepCounter = 1;

  for (let i = 1; i <= maxLevel - 1; i++) {
    // if (i === 2) continue; // Skip step 3

    steps.push({
      icon: "list",
      label: `Step ${stepCounter + 2}`, // Adjust for the skipped step
      index: stepCounter + 2,
    });

    stepCounter++;
  }

  return steps;
};

export default WizardLayout;
