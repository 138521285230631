import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";

export const baseURL = process.env.REACT_APP_URL;

axios.defaults.baseURL = baseURL;

axios.interceptors.request.use(
  (config) => {
    // config.withCredentials = true;
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      alert("Session expired");
    }
    return response;
  },
  (error) => {
    // alert(error?.message);
    console.log({ error });
    if (
      error.response?.status === 401 &&
      !error.config.url.includes("/authentication/login")
    ) {
      localStorage.clear();
      // document.cookie = "accessToken=; expires=thu, 01 jan 1970 00:00:00 utc;";
      alert("Authentication Error");
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export class API_BASE {
  uri;

  constructor(uri) {
    this.uri = uri;
  }

  async _execute(promise) {
    try {
      const response = await promise;
      return response;
    } catch (error) {
      console.log({ error });
      let err = error;
      if (error.response) {
        console.log({ errorResponse: error.response });
        err = error.response.data || error.message;
      }
      // return (err?.message || err?.error || err?.errors || err)
      console.log({ err });
      // alert(err?.message || err?.error || err?.errors || err);
      return Promise.reject(err?.message || err?.error || err?.errors || err);
    }
  }

  async get(request) {
    const { params, path = "" } = request || {};
    return await this._execute(axios.get(`${this.uri}${path}`, { params }));
  }

  async getById(id) {
    return await this._execute(axios.get(`${this.uri}/${id}`));
  }

  async post(data, request) {
    const { params, path = "" } = request || {};
    return await this._execute(
      axios.post(`${this.uri}${path}`, data, { params })
    );
  }

  async passwordPost(id, data) {
    return await this._execute(axios.post(`${this.uri}/${id}`, data));
  }

  async put(id, data) {
    return await this._execute(axios.put(`${this.uri}/${id}`, data));
  }

  async patch(id, data) {
    return await this._execute(axios.patch(`${this.uri}/${id}`, data));
  }

  async status(id, data) {
    return await this._execute(axios.post(`${this.uri}/status/${id}`, data));
  }

  async delete(id, request) {
    const { path = "" } = request || {};
    return await this._execute(axios.delete(`${this.uri}${path}/${id}`));
  }

  async getReturnByParentId(id) {
    return await this._execute(axios.get(`${this.uri}/return/${id}`));
  }
}
