import { useQuery } from "@tanstack/react-query";
import { FieldSetsAPI } from "api";

const fetchDetailFieldSet = () => {
  return FieldSetsAPI.get();
};
export const useFetchDetailsFieldSets = () => {
  return useQuery({
    queryKey: ["fieldSets"],
    queryFn: fetchDetailFieldSet,
  });
};
